import React, { useState } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { FilterAlt } from '@mui/icons-material'
import { useDispatch, useSelector } from "react-redux";
import { PostAuthRoutes } from '../../routes'
import { Card, CardContent, CardHeader } from '../../_components/card'
import { GeneralButton } from '../../_components/controls'
import { UpdateStatusModal } from '../../_components/modal'
import { FormSearchInput } from '../../_components/form'
import { FaqAction } from '../../redux/actions'
import { FaqDataTable } from '.'
import { FaqFilter } from './faq-filter'
import { useHistory, useLocation } from 'react-router'
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

function Faq() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' })
    const [showFaqFilterModal, setShowFaqFilterModal] = useState(false);

    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: { status: searchParams.get('status') ?? '' },
        search: searchParams.get('q') ?? ''

    });

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const changeStatus = (params) => dispatch(FaqAction.changeFaqStatus(params));

    /**
     * function to create FAQ
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 10 Jan 2023
     */
    function createFaq() {
        history.push({
            pathname: PostAuthRoutes('create_faq').path,

        });
    }

    /**
     * function to open/close FAQ status Update modal
     * @param {string} null 
     * @return view
     * @author Naveen
     * @created_at 12 Jan 2023
     */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to Update FAQ status
     * @return view
     * @author Naveen
     * @created_at 12 Jan 2023
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'faq_id': id });
    }

    const faqFilterModal = () => {
        setShowFaqFilterModal(!showFaqFilterModal);
    }

    /**
     * function to apply the selected filter options on the FAQ list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the FAQ filter modal
     * @return view
     * @author Naveen
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the FAQ list
     * @param {String} text Search keyword used
     * @return view
     * @author Naveen
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    /**
     * function to direct to sort page
     * @param {string} id - Id of the data which we need to display
     * @return json
     * @author Naveen
     * @created_at 13 Jan 2023
     */
    function sortFaq() {
        history.push({
            pathname: PostAuthRoutes('sort_faq').path,
        });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('faq').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='faq_create' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton
                                label={<React.Fragment>Add</React.Fragment>}
                                onClick={() => createFaq()}
                            />
                        </AbilityCan>
                        <AbilityCan I='faq_sort' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton
                                label={<React.Fragment>Sort</React.Fragment>}
                                onClick={() => sortFaq()}
                            />
                        </AbilityCan>
                        <AbilityCan I='faq_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='faq_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton
                                label={<FilterAlt />}
                                onClick={() => faqFilterModal()}
                            />
                        </AbilityCan>
                    </Stack>
                } />
            </Box>
            <FaqFilter show={showFaqFilterModal} closeModal={faqFilterModal} applyFilter={applyFilter} faqFilter={payload.filter} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardContent>
                            <FaqDataTable statusUpdateModal={statusUpdateModal} filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
        </React.Fragment>

    )

}

export { Faq }