import { Box } from '@mui/material'
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { InfluencerAction } from "../../../redux/actions";
import { PostAuthRoutes } from '../../../routes'
import { CardHeader as PageHeader } from "../../../_components/card";
import { InfluencerCard, InfluencerCardLoader } from '../influencer-card';
import { UpdateStatusModal } from '../../../_components/modal';
import { NoData } from '../../../_components/layout/no-data';
import { Tabs } from '../../../_components/tabs';
import { InfluencerStories } from '../influencer-stories';

function InfluencerDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    /** influencer id and details on influencer page */
    const params = new URLSearchParams(search);
    const influencerId = params.get('id') // get influencer id from url

    /** Redux actions and state */
    const { influencer_details_loading: isLoading, influencer_details } = useSelector((state) => state.InfluencerReducer);

    const influencerDetails = (params) => dispatch(InfluencerAction.influencerDetails(params));
    const changeStatus = (params) => dispatch(InfluencerAction.changeStatus(params));

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });

    useEffect(() => {
        influencerDetails({ influencer_id: influencerId });
        //eslint-disable-next-line
    }, [influencerId])



    /**
    * function to open/close status Update modal 
    * @return view
    * @author
    * @created_at 14 June 2022
    */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update Influencer
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'influencer_id': id });
    }
    
    function InfluencerDetailTabPanel() {
        return (
            <React.Fragment>
                {(!isLoading && Object.keys(influencer_details).length > 0) &&
                    <Box sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                        <Box sx={{ maxWidth: `1000px` }}>
                            <InfluencerCard influencer={influencer_details} height="80vh" actions={{ detail: false, change_status: true }} statusUpdateModal={statusUpdateModal} />
                        </Box>
                    </Box>
                }
                {(isLoading) && 
                    <Box sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                        <Box sx={{ maxWidth: `1000px` }}>
                            <InfluencerCardLoader count={1} />
                        </Box>
                    </Box>
                }
                <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
            </React.Fragment>
            
            
        )
    }

    return (
        <React.Fragment>
            <PageHeader title={PostAuthRoutes('influencer_details').name} />
            {!isLoading && Object.keys(influencer_details).length === 0 && <NoData content1={`No`} content2={`Influencer available`} minHeight={`55vh`} ></NoData>}
            <Tabs
                groupName={'idTab'}
                data={[
                    { slug: "influencer", label: "Influencer", component: <InfluencerDetailTabPanel /> },
                    { slug: "stories", label: "Stories", component: <InfluencerStories /> },
                ]}
            />
        </React.Fragment>
    );

}

export { InfluencerDetails }