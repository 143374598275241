import { NewsletterSubscribersServices } from "../services";

export const NEWSLETTER_SUBSCRIBERS_REQUEST = 'NEWSLETTER_SUBSCRIBERS_REQUEST';
export const NEWSLETTER_SUBSCRIBERS_SUCCESS = 'NEWSLETTER_SUBSCRIBERS_SUCCESS';
export const NEWSLETTER_SUBSCRIBERS_FAILURE = 'NEWSLETTER_SUBSCRIBERS_FAILURE';

export const NewsletterSubscribersAction = {

    /**
     * function to get newsletter subscribers list
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    getNewsletterSubscribersList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return NewsletterSubscribersServices.getNewsletterSubscribersList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: NEWSLETTER_SUBSCRIBERS_REQUEST, request: request } }
        function success(request, response) { return { type: NEWSLETTER_SUBSCRIBERS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: NEWSLETTER_SUBSCRIBERS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },
};
