import { Add, FilterAlt } from '@mui/icons-material'
import { Grid, Stack } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { PostAuthRoutes } from '../../routes'
import { GeneralButton } from '../../_components/controls'
import { InvoiceDataTable } from '.'
import { InvoiceFilter } from './invoice-filter'
import { FormSearchInput } from '../../_components/form'
import { useLocation } from 'react-router-dom'
import { InvoiceAction } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { CardHeader, Card, CardContent } from '../../_components/card'
import { AbilityCan } from '../../_helpers/permission/AbilityCan'
import { TotalWalletCardCard, TotalMonthWalletCardCard } from '../dashboard';

function Invoice() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Initialize and declare state */
    const [showInvoiceFilterModal, setShowInvoiceFilterModal] = useState(false);
    const [payload, setPayload] = useState({ limit: 100, page: 1, filter: { status: searchParams.get('status') ?? '', promo_date: searchParams.get('promo_date') ?? '' }, search: searchParams.get('q') ?? '' });

    /** Redux reducer state */
    const { user } = useSelector((state) => state.UserReducer);
    const generateInvoice = (params) => dispatch(InvoiceAction.generateInvoice(params));

    /**
     * function to open/close invoice filter modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const invoiceFilterModal = () => {
        setShowInvoiceFilterModal(!showInvoiceFilterModal);
    }

    /**
     * function to create invoice 
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const generate = async () => {
        const isGenerated = await generateInvoice();
        if (isGenerated.status) {
            setPayload({ ...payload, page: 1 });
        }
    }

    /**
     * function to apply the selected filter options on the invoice list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the invoice filter modal
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the invoice list
     * @param {String} text Search keyword used
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('invoice').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`} >
                            <AbilityCan I='invoice_generate' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <GeneralButton startIcon={<Add />} label={'Generate Invoice'} onClick={() => generate()} />
                            </AbilityCan>
                            <AbilityCan I='invoice_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            </AbilityCan>
                            <AbilityCan I='invoice_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <GeneralButton label={<FilterAlt />} onClick={() => invoiceFilterModal()} />
                            </AbilityCan>
                        </Stack>
                    } />
            </Box>
            <InvoiceFilter show={showInvoiceFilterModal} closeModal={invoiceFilterModal} applyFilter={applyFilter} invoiceFilter={payload.filter} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <TotalWalletCardCard />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <TotalMonthWalletCardCard />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardContent>
                            <InvoiceDataTable filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </React.Fragment>
    )
}

export { Invoice }
