import { FilterAlt } from "@mui/icons-material";
import { Grid, Stack } from "@mui/material";
import { CardHeader } from '../../_components/card'
import Box from '../../_components/box'
import { FormSearchInput } from '../../_components/form';

import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom'
import { PlanAction } from "../../redux/actions";
import { PostAuthRoutes } from "../../routes";
import { RouteLink } from "../../_components/controls";
import { GeneralButton } from '../../_components/controls/general-button/GeneralButton'
import { UpdateStatusModal } from "../../_components/modal/modals/UpdateStatusModal";
import { PlanCardLoader, PlanFilter, PlanCard } from "./";
import { NoData } from "../../_components/layout/no-data";
import { AbilityCan } from "../../_helpers/permission/AbilityCan";

function Plans() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { plans_loading: isLoading, plans, get_installation_cost_loading: installationCostLoading, installation_cost, giveaway_plans_loading: isGiveawayPlansLoading, giveaway_plans } = useSelector((state) => state.PlanReducer);
    const listPlans = (params) => dispatch(PlanAction.listPlans(params));
    const listGiveawayPlans = (params) => dispatch(PlanAction.listGiveawayPlans(params));
    const changeStatus = (params) => dispatch(PlanAction.changeStatus(params));
    const getInstallationCost = (params) => dispatch(PlanAction.getInstallationCost(params));

    /** Initialize and declare state */
    const [showPlanFilterModal, setShowPlanFilterModal] = useState(false);
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });

    const [payload, setPayload] = useState({
        limit: 100, page: 1, giveaway_page: 1,filter: {
            status: searchParams.get('status') ?? '',
            filter_category: searchParams.get('filter_category') ?? '',
            search: searchParams.get('q') ?? ''
        }
    });

    useEffect(() => {

        getNext() // Initially load plan list
        getGiveawayNext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        getInstallationCost({ slug: 'plan_installation_cost' });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = payload.page;
        queryParam.g_p = payload.giveaway_page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
        if (payload.filter.filter_category) queryParam.filter_category = payload.filter.filter_category; else delete queryParam.filter_category;
        let pageUrl = `${PostAuthRoutes('plans').path}?${queryString.stringify(queryParam)}`;

        window.history.pushState({}, '', pageUrl);
        getNext() // Initially load participants list

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
     * function to list Plan
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNext = async () => {
        const result = await listPlans(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    
    /**
     * function to list Plan
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getGiveawayNext = async () => {
        const result = await listGiveawayPlans(payload);
        if (result.status) {
            setPayload({ ...payload, giveaway_page: payload.giveaway_page + 1 });
        }
    }

    /**
     * function to open/close Plan filter modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const planFilterModal = () => {
        setShowPlanFilterModal(!showPlanFilterModal);
    }

    /**
     * function to apply the selected filter options on the Plan list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the Plan filter modal
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, giveaway_page: 1, filter: filterData })
    }

    /**
     * function to open/close status Update modal 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update Plan
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'plan_id': id });
    }

    /**
     * function to apply the search key words on the plan list
     * @param {String} text Search keyword used
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, giveaway_page: 1, search: text });
    }

    const InstallationCost = () => {
        const toPath = (!installationCostLoading && Object.keys(installation_cost).length > 0) ? 'update_installation_cost' : 'create_installation_cost';
        return (
            <RouteLink to={PostAuthRoutes(toPath).path} label={<GeneralButton label={PostAuthRoutes(toPath).name} disabled={installationCostLoading} />} />
        )
    }


    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('plans').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='plans_create' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <RouteLink to={PostAuthRoutes('create_plans').path} label={<GeneralButton label={PostAuthRoutes('create_plans').name} />} />
                        </AbilityCan>
                        <AbilityCan I='plans_installation_cost' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <InstallationCost />
                        </AbilityCan>
                        <AbilityCan I='plans_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='plans_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<FilterAlt />} onClick={() => planFilterModal()} />
                        </AbilityCan>
                    </Stack>
                } />
                <PlanFilter show={showPlanFilterModal} closeModal={planFilterModal} applyFilter={applyFilter} planFilter={payload.filter} />

                <InfiniteScroll dataLength={plans.length} next={getNext} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="stretch">
                        {
                            plans.length > 0 && plans.map((plan, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3} key={i}><PlanCard plan={plan} statusUpdateModal={statusUpdateModal} /></Grid>
                                );
                            })
                        }
                        {isLoading && <PlanCardLoader />}
                        {!isLoading && plans.length === 0 && <NoData content1={`No`} content2={`Plans available`} description={`Please click below button to create new plan.`} minHeight={`55vh`} > <RouteLink to={PostAuthRoutes('create_plans').path} label={<GeneralButton label={PostAuthRoutes('create_plans').name} />} /></NoData>}
                    </Grid>
                </InfiniteScroll>
            </Box>


            {(!isGiveawayPlansLoading && giveaway_plans.length > 0) &&
                <React.Fragment>
                    <Box>
                        <CardHeader title='Giveaway Plans' />
                    </Box>

                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="stretch">
                        {
                            giveaway_plans.length > 0 && giveaway_plans.map((plan, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3} key={i}><PlanCard plan={plan} statusUpdateModal={statusUpdateModal} /></Grid>
                                );
                            })
                        }
                    </Grid>
                </React.Fragment>
            }

            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />


        </React.Fragment>

    )
}


export { Plans }