import React, { useEffect, useState } from 'react'
import { Avatar, Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { NotificationAction } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AccessTime } from '@mui/icons-material';
import { CardHeader } from '../../../_components/card/card-header/CardHeader';
import { PostAuthRoutes } from '../../../routes';
import { NotificationsCardLoader } from ".";
import { NoData } from '../../../_components/layout/no-data';


function Notifications() {
    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { notifications_list_loading: isLoading, notifications_list } = useSelector((state) => state.NotificationReducer);
    const getNotificationList = (params) => dispatch(NotificationAction.getNotificationList(params));

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ limit: 100, page: 1 });

    useEffect(() => {
        getNext() // Initially load participants list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
    * function to list Notification
    * @param {string} null 
    * @return view
    * @author
    * @created_at 14 June 2022
    */
    const getNext = async () => {
        const result = await getNotificationList(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    return (
        <React.Fragment>
            <Box>
                {notifications_list.length > 0 && <CardHeader title={PostAuthRoutes('notifications').name} />}
                <InfiniteScroll dataLength={notifications_list.length} next={getNext} hasMore={true}>
                    <Grid container spacing={1}  >
                        {
                            notifications_list.length > 0 && notifications_list.map((notification, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i}>
                                        <Card>
                                            <CardContent sx={{ mt: 2 }}>
                                                <Box sx={{ pl: 4, pr: 4, pt: 1, pb: 1 }}>
                                                    <Grid container wrap="nowrap" spacing={2}>
                                                        <Grid item>
                                                            <Avatar src={notification.sender_profile_picture} />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Box>
                                                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                    <Typography variant="h6">{notification.sender_info.name}</Typography>
                                                                    <Typography variant="body2" sx={{ fontSize: 10 }}> <AccessTime /> {notification.timeAgo}</Typography>
                                                                </Stack>
                                                                <Typography variant="body2" sx={{ fontSize: 12 }}>{notification.description}</Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })
                        }
                        {!isLoading && notifications_list.length === 0 && <NoData content1={`No`} content2={`Notifications available`} description={`Stay tuned! Notifications about your activity will show up here.`} minHeight={`55vh`} ></NoData>}
                        {isLoading && <NotificationsCardLoader />}
                    </Grid>
                </InfiniteScroll>
            </Box>
        </React.Fragment>
    )
}

export { Notifications };
