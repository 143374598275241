import { Instagram, MoreVert, Email, Phone, CheckCircle } from '@mui/icons-material'
import { Box, CardHeader, CardMedia, Chip, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Card, CardContent } from '../../../_components/card'
import { PostAuthRoutes } from '../../../routes'
import { AbilityCan } from '../../../_helpers/permission/AbilityCan'
import { AVATAR_BROKEN_IMAGE } from '../../../_helpers';
import { useSelector } from 'react-redux'

function InfluencerCard({ influencer, statusUpdateModal, height = `100%`, actions = { detail: true, change_status: true } }) {

    /** Initialize values from influencer props */
    const { _id, status, name, insta_profile, email, insta_handle, email_verify_status, mobile_number, mobile_verify_status } = influencer;

    /** Initialize and declare state */
    const [statusData, setStatusData] = useState({ id: _id, status: status, item: name });
    const [anchorEl, setAnchorEl] = React.useState(null);
    let history = useHistory();
    const open = Boolean(anchorEl);

    const { user } = useSelector((state) => state.UserReducer);

    useEffect(() => {
        setStatusData({ id: _id, status: status, item: name })
    }, [_id, status, name])

    /**
     * function to handle click menu
     * @param {object} event form object
     * @author  
     * @created_at 14 June 2022 
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to handle close menu
     * @param {string} null
     * @author  
     * @created_at 14 June 2022 
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * function to change influencer status 
     * @param {string} null
     * @author  
     * @created_at 14 June 2022 
     */
    function handleStatusChangeClick() {
        setAnchorEl(null);
        setTimeout(() => {
            statusUpdateModal(statusData);
        }, 100);
    }

    /**
     * function to get influencer details page
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    function handleDetailsClick() {
        setAnchorEl(null);
        history.push({
            pathname: PostAuthRoutes('influencer_details').path,
            search: `?id=${_id}`
        });
    }

    return (
        <Card sx={{ height: height, display: `flex`, alignItems: `center`, justifyContent: `center` }}>
            <CardHeader
                sx={{ width: `100%` }}
                action={
                    <React.Fragment>
                        <Chip size='small' label={status === 'active' ? 'Active' : 'Inactive'} color={status === 'active' ? 'success' : 'error'} />
                        <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                        <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}  >
                            <AbilityCan I='influencer_details' passThrough={(user.role_slug === 'developer') ? true : false}>
                                {actions.detail && <MenuItem onClick={handleDetailsClick}>{`Details`}</MenuItem>}
                            </AbilityCan>
                            <AbilityCan I='influencer_change_status' passThrough={(user.role_slug === 'developer') ? true : false}>
                                {actions.change_status && <MenuItem onClick={handleStatusChangeClick}>{status === 'active' ? 'Deactivate' : 'Activate'}</MenuItem>}
                            </AbilityCan>
                        </Menu>
                    </React.Fragment>
                } />
            <CardContent>
                <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: `200px` }}>
                            <CardMedia height={`100%`} component={"img"} src={insta_profile.profile_picture_url} onError={e => { e.target.src = AVATAR_BROKEN_IMAGE }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems={`center`} justifyContent={`center`}>
                        <Typography variant="h5" align='center' mb={1} onClick={handleDetailsClick} sx={{ cursor: `pointer` }}>{`${name}`}</Typography>
                        <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Instagram />&nbsp;{`@${insta_handle}`}</Typography>
                        {email ? <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Email />&nbsp;{`${email}`}&nbsp;{email_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='light' size='small' />}</Typography> : <Typography variant="h6" align='center' >&nbsp;</Typography>}
                        {mobile_number ? <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Phone />&nbsp;{`${mobile_number}`}&nbsp;{mobile_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='light' size='small' />}</Typography> : <Typography variant="h6" align='center' >&nbsp;</Typography>}
                    </Grid>
                </Grid>
                <Grid container spacing={1} display={`flex`} alignItems={`center`} justifyContent={`center`} mt={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{ backgroundColor: 'info.main', width: `100%`, p: 1 }}  >
                            {insta_profile && <Typography Typography variant="h6" color={`white`} align={`center`}>{insta_profile.followers_count}</Typography>}
                            <Typography variant="body2" color={`white`} align={`center`}>{`Followers`}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    )

}

export { InfluencerCard }