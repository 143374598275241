import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { InfluencerAction } from '../../redux/actions/InfluencerAction'
import { Box, Grid, Stack } from '@mui/material'
import { UpdateStatusModal } from '../../_components/modal/modals/UpdateStatusModal'
import { CardHeader } from "../../_components/card";
import { GeneralButton } from "../../_components/controls/general-button/GeneralButton";
import { FilterAlt } from "@mui/icons-material";
import { PostAuthRoutes } from "../../routes";
import { InfluencerCard, InfluencerCardLoader, InfluencerFilter, InfluencerExport } from ".";
import { useLocation } from 'react-router-dom';
import { FormSearchInput } from '../../_components/form';
import { FilterMenu } from "../../_components/controls";
import { NoData } from "../../_components/layout/no-data";
import { AbilityCan } from "../../_helpers/permission/AbilityCan";


function Influencers() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const { search } = useLocation
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const listInfluencer = (params) => dispatch(InfluencerAction.listInfluencer(params));
    const changeStatus = (params) => dispatch(InfluencerAction.changeStatus(params));
    const { influencers_loading: isLoading, influencers } = useSelector((state) => state.InfluencerReducer);
    const { user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [payload, setPayload] = useState({ limit: 100, page: 1, filter: { status: searchParams.get('status') ?? '', date: searchParams.get('date') ?? '' }, search: searchParams.get('q') ?? '' });
    const [showInfluencerFilterModal, setShowInfluencerFilterModal] = useState(false);

    useEffect(() => {
        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
        if (payload.filter.date) queryParam.date = payload.filter.date; else delete queryParam.date;
        let pageUrl = `${PostAuthRoutes('influencer').path}?${queryString.stringify(queryParam)}`;

        window.history.pushState({}, '', pageUrl);
        getNext();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
     * function to list Influencer
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNext = async () => {
        const result = await listInfluencer(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    /**
     * function to open/close Influencer filter modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const influencerFilterModal = () => {
        setShowInfluencerFilterModal(!showInfluencerFilterModal);
    }

    /**
     * function to open/close status Update modal 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update Influencer
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'influencer_id': id });
    }

    /**
     * function to apply the selected filter options on the Influencer list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the Influencer filter modal
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to apply the search key words on the Influencer list
     * @param {String} text Search keyword used
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('influencer').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`} >
                            <AbilityCan I='influencer_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            </AbilityCan>
                            <AbilityCan I='influencer_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <GeneralButton label={<FilterAlt />} onClick={() => influencerFilterModal()} />
                            </AbilityCan>
                            <AbilityCan I='influencer_export_csv' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <FilterMenu>
                                    <InfluencerExport payload={payload} />
                                </FilterMenu>
                            </AbilityCan>
                        </Stack>
                    }
                />
                <InfluencerFilter show={showInfluencerFilterModal} applyFilter={applyFilter} closeModal={influencerFilterModal} influencerFilter={payload.filter} />
                <InfiniteScroll dataLength={influencers.length} next={getNext} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                        {
                            influencers.length > 0 && influencers.map((influencer, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3} key={i}>
                                        <InfluencerCard influencer={influencer} statusUpdateModal={statusUpdateModal} />
                                    </Grid>
                                );
                            })
                        }
                        {isLoading && <InfluencerCardLoader />}
                        {!isLoading && influencers.length === 0 && <NoData content1={`No`} content2={`Influencers available`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`55vh`} ></NoData>}
                    </Grid>
                </InfiniteScroll>
            </Box>
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
        </React.Fragment>
    )
}


export { Influencers }