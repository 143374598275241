import { Adjust, Article, AttachFile, CurrencyRupee, Event, FileDownload, Instagram, MoreVert, Numbers } from '@mui/icons-material'
import { Box, Chip, Divider, IconButton, Typography, Menu, MenuItem, Stack, Grid, CardMedia } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Card, CardContent } from '../../../_components/card'
import { PostAuthRoutes } from '../../../routes'
import { useHistory } from 'react-router'
import { AbilityCan } from '../../../_helpers/permission/AbilityCan'
import { POST_BROKEN_IMAGE } from '../../../_helpers';
import { useSelector } from 'react-redux'

function PromoCard({ promo, statusUpdateModal, featureUpdateModal, actions = { detail: true, change_feature: true, change_status: true } }) {

    /** Initialize values from promo props */
    const { _id, status, name } = promo

    /** Initialize and declare state */
    const [anchorEl, setAnchorEl] = React.useState(null);
    let history = useHistory();
    const open = Boolean(anchorEl);

    const [statusData, setStatusData] = useState({ id: _id, status: status, item: name });

    const { user } = useSelector((state) => state.UserReducer);

    useEffect(() => {
        setStatusData({ id: _id, status: status, item: name })
    }, [_id, status, name])

    /**
     * function to handle click menu
     * @param {object} event form object
     * @author  
     * @created_at 14 June 2022 
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to handle close menu
     * @param {string} null
     * @author  
     * @created_at 14 June 2022 
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
    * function to direct to Promo details page
    * @return view
    * @author
    * @created_at 14 June 2022
    */
    function handlePromoDetailsClick() {
        setAnchorEl(null);
        history.push({
            pathname: PostAuthRoutes('promo_details').path,
            search: `?pId=${promo._id}`
        });
    }

    /**
     * function to change promo features 
     * @param {string} null
     * @author  
     * @created_at 27 June 2022 
     */
    function handlePromoFeatureClick() {
        setAnchorEl(null);
        featureUpdateModal({ id: promo._id, feature: promo.feature });
    }

    /**
     * function to change promo status 
     * @param {string} null
     * @author Naveen
     * @created_at 18 July 2022 
     */
    function handleStatusChangeClick() {
        setAnchorEl(null);
        setTimeout(() => {
            statusUpdateModal(statusData);
        }, 100);
    }

    /**
    * function to direct to Brand details page
    * @return view
    * @author
    * @created_at 14 June 2022
    */
    function handleBrandDetailsClick() {
        setAnchorEl(null);
        history.push({
            pathname: PostAuthRoutes('brand_details').path,
            search: `?brandId=${promo.brand_id}`
        });
    }

    /**
     * function to direct to update promo page
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleUpdateClick() {
        setAnchorEl(null);
        history.push({
            pathname: PostAuthRoutes('update_promos').path,
            state: { promo: promo },
        });
    }

    return (
        <Card style={{ height: '100%' }}>
            <Grid container spacing={1} display="flex" alignItems="stretch" style={{ height: '100%' }}>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} >
                    <CardMedia component={"img"} src={promo.file} style={{ position: "relative", padding: 0, margin: 0, width: "100%", height: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onError={e => { e.target.src = POST_BROKEN_IMAGE }} />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7} pr={2}>
                    <CardContent>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1} mt={2}>
                            <Typography variant="h5" onClick={handlePromoDetailsClick} sx={{ cursor: `pointer` }}>{`${promo.name}`}</Typography>
                            <Box>
                                {promo.active_today &&
                                    <Chip size='small' label='Active Today' color='primary' sx={{ mr: 1 }} />
                                }
                                <Chip size='small' label={promo.status === 'active' ? 'Active' : 'Expired'} color={promo.status === 'active' ? 'success' : 'error'} />
                                {actions.detail && actions.change_feature &&
                                    <React.Fragment>
                                        <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                                        <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}  >
                                            <AbilityCan I='promo_details' passThrough={(user.role_slug === 'developer') ? true : false}>
                                                {actions.detail && <MenuItem onClick={handlePromoDetailsClick} >{`Details`}</MenuItem>}
                                            </AbilityCan>
                                            <AbilityCan I='promo_features' passThrough={(user.role_slug === 'developer') ? true : false}>
                                                {actions.change_feature && <MenuItem onClick={handlePromoFeatureClick} >{promo.feature === 'true' ? 'Make Unfeatured' : 'Make Featured'}</MenuItem>}
                                            </AbilityCan>
                                            <AbilityCan I='promo_change_status' passThrough={(user.role_slug === 'developer') ? true : false}>
                                                {actions.change_status && <MenuItem onClick={handleStatusChangeClick}>{status === 'active' ? 'Deactivate' : 'Activate'}</MenuItem>}
                                            </AbilityCan>
                                            <AbilityCan I='update_promo' passThrough={(user.role_slug === 'developer') ? true : false}>
                                                <MenuItem onClick={handleUpdateClick}>{`Update`}</MenuItem>
                                            </AbilityCan>
                                        </Menu>
                                    </React.Fragment>
                                }
                            </Box>
                        </Box>
                        <Box style={{ height: `150px`, overflowY: `scroll` }}>
                            <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>{`${promo.description}`}</Typography>
                        </Box>

                        <Divider />
                        {promo.brand_details &&
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2"> {`Brand Name : `}</Typography>
                                <Typography variant="h6" onClick={handleBrandDetailsClick}>{`${promo.brand_details.name}`}</Typography>
                            </Box>
                        }
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body2"><Instagram /> {`Instagram Handle : `}</Typography>
                            <Typography variant="h6">{`@${promo.insta_handle}`}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body2"><Event /> {`Start Date : `}</Typography>
                            <Typography variant="h6">{`${promo.promo_start_date}`}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body2"><Event /> {`End Date : `}</Typography>
                            <Typography variant="h6">{`${promo.promo_end_date}`}</Typography>
                        </Box>

                        <Divider />

                        <Stack spacing={1}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2">{`Promo type : `}</Typography>
                                <Chip label={`${promo.promo_type}`} size="small" icon={<AttachFile />} />
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2">{`Promo payment method : `}</Typography>
                                <Chip label={`${promo.payment_method}`} size="small" icon={<Adjust />} />
                            </Box>
                            {promo.payment_method === 'price' &&
                                <Box display="flex" justifyContent="space-between" alignItems="center" >
                                    <Typography variant="body2">{`Promo cost per post : `}</Typography>
                                    <Chip label={`${promo.promo_cost_per_influencer}/-`} size="small" icon={<CurrencyRupee />} />
                                </Box>
                            }
                            {promo.payment_method === 'coupon' &&
                                <Box display="flex" justifyContent="space-between" alignItems="center" >
                                    <Typography variant="body2">{`Coupon file uploaded : `}</Typography>
                                    <Chip component="a" href={promo.coupon_code_file} label={`Download`} clickable size="small" icon={<FileDownload />} />
                                </Box>
                            }
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2">{`Number of promos : `}</Typography>
                                <Chip component="a" href={`${PostAuthRoutes('promo_details').path}?pId=${promo._id}`} clickable label={`${promo.used_coupon_count}/${promo.coupon_codes}`} size="small" icon={<Numbers />} />
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2">{`Number of stories : `}</Typography>
                                <Chip component="a" href={`${PostAuthRoutes('promo_details').path}?pId=${promo._id}`} clickable label={`${promo.used_coupon_count}/${promo.number_of_promos}`} size="small" icon={<Article />} />
                            </Box>
                            {promo.thank_you_message && !actions.detail &&
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2" style={{ minWidth: "35%" }}>{`Thank You Message : `}</Typography>
                                    <Box style={{ height: `60px`, overflowY: `scroll` }} >
                                        <Typography variant="body2" size="small" style={{ whiteSpace: 'pre-line' }}>{`${promo.thank_you_message}`}</Typography>
                                    </Box>
                                    {/* <Chip component="a" label={`${promo.promo_start_date}`} size="small"/> */}
                                </Box>
                            }
                            {promo.coupon_message && !actions.detail &&
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2" style={{ minWidth: "35%" }}>{`Coupon Message : `}</Typography>
                                    <Box style={{ height: `60px`, overflowY: `scroll` }} >
                                        <Typography variant="body2" size="small" style={{ whiteSpace: 'pre-line' }}>{`${promo.coupon_message}`}</Typography>
                                    </Box>
                                </Box>
                            }
                            {promo.issue_coupons_at && !actions.detail &&
                                <Box display="flex" justifyContent="space-between" alignItems="center" >
                                    <Typography variant="body2">{`Issue Coupon At : `}</Typography>
                                    <Typography variant="body2" size="small">{`${promo.issue_coupons_at}`}</Typography>
                                </Box>
                            }
                        </Stack>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    )
}

export { PromoCard }