import { apiServices } from '../../_helpers';


export const PromoServices = {

    /**
     * Service for list brand promos
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     * @created_at 15 June 2022
     */
    listBrandPromos: (params) => {
        return apiServices.post('/admin/brand/promo', params)
            .then((response) => { return response; })

    },

    /**
     * Service for change brand promo status
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
    */
    changeStatus: (params) => {
        return apiServices.put('/admin/brand/promo/change-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service for update promo
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @param {String} params.name
     * @param {String} params.insta_handle
     * @param {String} params.description
     * @param {String} params.promo_type
     * @param {String} params.promo_start_date
     * @param {String} params.promo_end_date
     * @param {String} params.number_of_promos
     * @param {String} params.promo_cost_per_influencer
     * @param {Object} params.file
     * @author Jasin 
     * @created_at 15 June 2022
    */
    updatePromo: (params) => {
        return apiServices.put('admin/promo/update', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },

    /**
     * Service for promo details
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    promoDetails: (params) => {
        return apiServices.post('/admin/promo/details', params)
            .then((response) => { return response; })

    },

    /**
     * Service for list promo coupon codes
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     * @created_at 15 June 2022
     */
    listPromoCouponCodes: (params) => {
        return apiServices.post('/admin/promo/coupon-codes', params)
            .then((response) => { return response; })

    },

    /**
     * Service for list promos
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 21 April 2022
     */
    listPromos: (params) => {
        return apiServices.post('/admin/promo/list', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get promo stories
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 21 April 2022
    */
    promoStoryDetails: (params) => {
        return apiServices.post('/admin/promo/story', params)
            .then((response) => { return response; })

    },

    /**
     * Service to export promo CSV
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 20 June 2022
    */
    exportPromoCsv: (params) => {
        return apiServices.post('/admin/promo/export-csv', params)
            .then((response) => { return response; })

    },

    /**
     * Service for change promo Feature
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 27 June 2022
    */
    changeFeature: (params) => {
        return apiServices.put('/admin/promo/change-feature', params)
            .then((response) => { return response; })

    },

    /**
     * Service for change Promo status
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 18 July 2022
    */
    changePromoStatus: (params) => {
        return apiServices.put('/admin/promo/change-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service for change brand promo Feature
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 27 June 2022
    */
    changePromoFeature: (params) => {
        return apiServices.put('/admin/brand/promo/change-feature', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get login activity list
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    redeemCoupon: (params) => {
        return apiServices.post('/admin/promo/coupon-codes/redeem', params)
            .then((response) => { return response; })
    },

    createPromo: (params) => {
        return apiServices.post('admin/promo/create', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },

    /**
     * Service for list promo coupon codes
     * @author Akshay N 
     */
    getRunningPromos: (params) => {
        return apiServices.post('/admin/promo/running', params)
            .then((response) => { return response; })

    },

};
