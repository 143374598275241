import {
    USER_REQUEST, USER_SUCCESS, USER_FAILURE,
    CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE,
    GET_ACTIVITY_LOG_REQUEST, GET_ACTIVITY_LOG_SUCCESS, GET_ACTIVITY_LOG_FAILURE,
    GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE,
    ADD_USER_REQUEST, ADD_USER_SUCCESS, ADD_USER_FAILURE,
    UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE,
    CHANGE_USER_STATUS_REQUEST, CHANGE_USER_STATUS_SUCCESS, CHANGE_USER_STATUS_FAILURE,
    USER_PERMISSION_REQUEST, USER_PERMISSION_SUCCESS, USER_PERMISSION_FAILURE,
    USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAILURE,

} from "../actions";

const initialState = {
    user_loading: true, user: {}, user_errors: [],
    change_password_loading: false, change_password: {}, change_password_errors: [],
    activity_logs_loading: false, activity_logs: [], activity_logs_errors: {},
    users_loading: false, users: [], users_errors: [],
    add_user_loading: false, add_user_errors: [],
    change_user_status_loading: false, change_user_status_errors: {},
    user_permission_loading: true, user_permission: [], user_permission_errors: [],
};

export function UserReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::User details redux */
        case USER_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                user: action.data,
                user_errors: {}
            };
        }
        case USER_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::User details redux */

        /** begin::Change password redux */
        case CHANGE_PASSWORD_REQUEST: {
            return {
                ...state,
                change_password_loading: true,
                change_password_errors: {}
            };
        }
        case CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                change_password_loading: false,
                change_password: action.data,
                change_password_errors: {}
            };
        }
        case CHANGE_PASSWORD_FAILURE: {
            return {
                ...state,
                change_password_loading: false,
                change_password_errors: action.errors
            };
        }
        /** end::Change passwor redux */

        /** begin::List login activity redux */
        case GET_ACTIVITY_LOG_REQUEST: {
            return {
                ...state,
                activity_logs_loading: true,
                activity_logs_errors: {}
            };
        }
        case GET_ACTIVITY_LOG_SUCCESS: {

            let newArray = [...state.activity_logs]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.activities.forEach((payload) => {
                const loginActivityIndex = newArray.findIndex(activityLog => activityLog._id === payload._id);
                if (loginActivityIndex !== -1) {
                    newArray[loginActivityIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                activity_logs_loading: false,
                activity_logs_errors: {},
                activity_logs: newArray
            };

        }
        case GET_ACTIVITY_LOG_FAILURE: {
            return {
                ...state,
                activity_logs_loading: false,
                activity_logs_errors: action.errors,
                activity_logs: []
            };
        }

        /** end::List login activity redux */

        /** begin::Users list redux */
        case GET_USERS_REQUEST: {
            return {
                ...state,
                users_loading: true,
                users_errors: {}
            };
        }
        case GET_USERS_SUCCESS: {
            let newArray = [...state.users]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.admins.forEach((payload) => {
                const loginActivityIndex = newArray.findIndex(users => users._id === payload._id);
                if (loginActivityIndex !== -1) {
                    newArray[loginActivityIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                users_loading: false,
                users: action.data.admins,
                users_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        }
        case GET_USERS_FAILURE: {
            return {
                ...state,
                users_loading: false,
                users: [],
                users_errors: action.errors
            };
        }
        /** end::Users list redux */

        /** begin::Create Users redux */
        case ADD_USER_REQUEST: {
            return {
                ...state,
                add_user_loading: true,
                add_user_errors: {}
            };
        }
        case ADD_USER_SUCCESS: {
            return {
                ...state,
                add_user_loading: false,
                add_user_errors: {},
            };
        }
        case ADD_USER_FAILURE: {
            return {
                ...state,
                add_user_loading: false,
                add_user_errors: action.errors
            };
        }
        /** end::Create Users redux */

        /** begin::Update Users redux */
        case UPDATE_USER_REQUEST: {
            return {
                ...state,
                add_user_loading: true,
                add_user_errors: {}
            };
        }
        case UPDATE_USER_SUCCESS: {
            return {
                ...state,
                add_user_loading: false,
                add_user_errors: {},
            };
        }
        case UPDATE_USER_FAILURE: {
            return {
                ...state,
                add_user_loading: false,
                add_user_errors: action.errors
            };
        }
        /** end::Update Users redux */

        /** begin::Change User status redux */
        case CHANGE_USER_STATUS_REQUEST: {
            return {
                ...state,
                change_user_status_loading: true,
                change_user_status_errors: {}
            };
        }
        case CHANGE_USER_STATUS_SUCCESS: {
            let usersArray = [...state.users];
            const userIndex = usersArray.findIndex(users => users._id === action.data._id);

            if (userIndex !== -1) {
                usersArray[userIndex].status = action.data.status;
            }

            return {
                ...state,
                change_user_status_loading: false,
                change_user_status_errors: {},
                users: usersArray
            };
        }
        case CHANGE_USER_STATUS_FAILURE: {
            return {
                ...state,
                change_user_status_loading: false,
                change_user_status_errors: action.errors
            };
        }
        /** end::Change User status redux */

        /** begin::User details redux */
        case USER_PERMISSION_REQUEST: {
            return {
                ...state,
                user_permission_loading: true,
                user_permission_errors: {}
            };
        }
        case USER_PERMISSION_SUCCESS: {
            return {
                ...state,
                user_permission_loading: false,
                user_permission: action.data.permissions,
                user_permission_errors: {}
            };
        }
        case USER_PERMISSION_FAILURE: {
            return {
                ...state,
                user_permission_loading: false,
                user_permission_errors: action.errors,
                user_permission: []
            };
        }
        /** end::User details redux */

        /** begin::User details redux */
        case USER_UPDATE_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_UPDATE_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                users: action.data,
                user_errors: {}
            };
        }
        case USER_UPDATE_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::User details redux */
        default:
            return state;
    }
}
