import { EmailLogServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const EMAIL_LOGS_REQUEST = 'EMAIL_LOGS_REQUEST';
export const EMAIL_LOGS_SUCCESS = 'EMAIL_LOGS_SUCCESS';
export const EMAIL_LOGS_FAILURE = 'EMAIL_LOGS_FAILURE';

export const EMAIL_LOG_DETAILS_REQUEST = 'EMAIL_LOG_DETAILS_REQUEST';
export const EMAIL_LOG_DETAILS_SUCCESS = 'EMAIL_LOG_DETAILS_SUCCESS';
export const EMAIL_LOG_DETAILS_FAILURE = 'EMAIL_LOG_DETAILS_FAILURE';

export const EMAIL_LOG_RESEND_REQUEST = 'EMAIL_LOG_RESEND_REQUEST';
export const EMAIL_LOG_RESEND_SUCCESS = 'EMAIL_LOG_RESEND_SUCCESS';
export const EMAIL_LOG_RESEND_FAILURE = 'EMAIL_LOG_RESEND_FAILURE';

export const EmailLogAction = {

    /**
     * Action for list email logs
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    listEmailLogs: (params) => {
        return dispatch => {
            dispatch(request(params));
            return EmailLogServices.listEmailLogs(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: EMAIL_LOGS_REQUEST, request: request } }
        function success(request, response) { return { type: EMAIL_LOGS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: EMAIL_LOGS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get email log details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.request_id
     * @author Jasin 
     * @created_at 15 June 2022
    */
    emailLogDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return EmailLogServices.emailLogDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: EMAIL_LOG_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: EMAIL_LOG_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: EMAIL_LOG_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


    /**
     * Action to get resed email
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.request_id
     * @author Jasin 
     * @created_at 15 June 2022
    */
    ResendMail: (params) => {
        return dispatch => {
            dispatch(request(params));
            return EmailLogServices.ResendMail(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: EMAIL_LOG_RESEND_REQUEST, request: request } }
        function success(request, response) { return { type: EMAIL_LOG_RESEND_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: EMAIL_LOG_RESEND_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};
