import React, { useEffect, useState } from 'react'
import queryString from 'query-string';
import { DataTable } from '../../_components/data-table/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InvoiceAction } from '../../redux/actions';
import { ButtonGroup, Chip } from '@mui/material';
import { PostAuthRoutes } from '../../routes';
import { Download } from '@mui/icons-material';
import { AbilityCan } from '../../_helpers/permission/AbilityCan'

function InvoiceDataTable({ filterAction, filter, search, limit, page, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { per_page, total, invoices_loading: isLoading, invoices } = useSelector((state) => state.InvoiceReducer);
    const { user } = useSelector((state) => state.UserReducer);

    const listInvoices = (params) => dispatch(InvoiceAction.listInvoices(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ filter, search, page, limit });

    const columns = [
        { name: '#', selector: (row, i) => (row._id), sortField: '_id', center: true, width: '5%', cell: (row, i) => ((per_page * (payload.page - 1)) + (i + 1)) },
        { name: 'Brand', selector: (row, i) => (row.brand_details[0].name), sortField: 'brand_details.name[0]', sortable: true, width: '17%' },
        { name: 'Invoice Number', selector: (row, i) => (row.invoice_number), sortField: 'invoice_number', sortable: true, width: '18%', style: { whiteSpace: `unset` }, cell: row => (<InvoiceNumberRow row={row} />) },
        { name: 'Plan', selector: (row, i) => (row.plan_details.name), sortField: 'plan_details.name', center: true, sortable: true, wrap: true, width: '16%' },
        { name: 'Coupons', selector: (row, i) => (row.plan_coupons), sortField: 'plan_coupons', center: true, sortable: true, wrap: true, width: '10%' },
        { name: 'Amount (Rs)', selector: (row, i) => (row.total_amount), sortField: 'total_amount', center: true, sortable: true, width: '10%' },
        { name: 'Created Date', selector: (row, i) => (row.created_at), sortField: 'created_at', center: true, sortable: true, width: '14%' },
        { name: 'Status', selector: (row, i) => (row.status), sortField: 'status', center: true, sortable: true, width: '10%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const StatusRow = ({ row }) => {
        let status = <Chip label={row.status} className="badge-status capitalize" />;
        if (row.status === 'paid')
            status = <Chip label={row.status} color="success" className="badge-status capitalize" />;
        else if (row.status === 'not_paid')
            status = <Chip label={row.status} color="error" className="badge-status capitalize" />;
        else if (row.status === 'pending')
            status = <Chip label={row.status} color="error" className="badge-status capitalize" />;

        let statusHover = (
            <ButtonGroup variant="text" size="small" className='row-action'>
                <AbilityCan I='invoice_pdf' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <a href={row.invoice_pdf} download>{<Download />}</a>
                </AbilityCan>
            </ButtonGroup>
        )
        return <React.Fragment>{status}{row.invoice_pdf && statusHover}</React.Fragment>;

    }

    /**
     * function to display invoice Number rows
     * @param {Object} row
     * @param {string} row.invoice_number - invoice_number values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const InvoiceNumberRow = ({ row }) => {
        return <a {...row.invoice_pdf ? { href: row.invoice_pdf, download: true } : {}}>{row.invoice_number}</a>;
    }

    useEffect(() => {
        setPayload({ filter, search, page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, limit])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        history.push({
            pathname: PostAuthRoutes('invoice').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search, payload.page])

    /**
     * function to fetch invoice list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @param {string} payload.search - Search key
     * @return json 
     */
    const getData = async () => {
        await listInvoices(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    /**
     * function to handle page
     * @param {String} page - Page number to load 
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={invoices}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSort={handleSort}
            handleSelect={handleSelect}
        />
    )
}


export { InvoiceDataTable };

