import styled from '@emotion/styled'
import { CurrencyRupee, MoreVert } from '@mui/icons-material'
import { Box, Chip, Divider, IconButton, Menu, MenuItem, Stack, Card, CardContent, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { PostAuthRoutes } from '../../../routes'
import { AbilityCan } from '../../../_helpers/permission/AbilityCan'

// Initialize form color values
const colors = { 'trial': '#ACDDDE', 'basic': '#CAF1DE', 'premium': '#E1F8DC', 'ultimate': '#FFE7C7' }

// Initialize plan card style
const PlanCardComponent = styled(({ ...props }) => (<Card {...props} />))`
        background-color:  ${props => (colors[props.plan])};
` ;


function PlanCard({ plan, statusUpdateModal, ...props }) {

    /** Initialize values from plan props */
    const { _id, name, status, number_of_coupons, category, created_at, description, cost, discount, discounted_cost, duration } = plan;

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [statusData, setStatusData] = useState({ id: _id, status: status, item: '' });
    const [anchorEl, setAnchorEl] = React.useState(null);

    /** Initialize plugins and variables */
    const open = Boolean(anchorEl);
    let history = useHistory();

    /**
     * function to handle click menu
     * @param {object} event form object
     * @author  
     * @created_at 14 June 2022 
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to handle close menu
     * @param {string} null
     * @author  
     * @created_at 14 June 2022 
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * function to direct to plan update page 
     * @param {string} null
     * @author  
     * @created_at 14 June 2022 
     */
    function handleUpdateClick() {
        setAnchorEl(null);
        history.push({
            pathname: PostAuthRoutes('update_plans').path,
            state: { plan: plan }
        });
    }

    /**
     * function to change plan status 
     * @param {string} null
     * @author  
     * @created_at 14 June 2022 
     */
    function handleStatusChangeClick() {
        setAnchorEl(null);
        setTimeout(() => {
            statusUpdateModal(statusData);
        }, 100);
    }

    /**
     * function to direct to plan details page
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    function handleDetailsClick() {
        setAnchorEl(null);
        history.push({
            pathname: PostAuthRoutes('plan_details').path,
            search: `?planId=${_id}`
        });
    }

    useEffect(() => {
        setStatusData({ id: _id, status: status, item: name })
    }, [_id, status, name])

    return (
        <PlanCardComponent {...plan} style={{ height: '100%' }}>
            <CardContent style={{ padding: '0.5rem 0.5rem 0.5rem 1.5rem' }}>
                <Box>
                    <Stack direction="row" display={`flex`} alignItems={`center`} justifyContent={`space-between`} spacing={1}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} mb={1} mt={2}>
                            <Typography variant="h5">{`${name}`}</Typography>
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} mb={1} mt={2}>
                            <Chip size='small' label={status === 'active' ? 'Active' : 'Inactive'} color={status === 'active' ? 'success' : 'error'} />
                            <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                            <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}  >
                                <AbilityCan I='plans_change_status' passThrough={(user.role_slug === 'developer') ? true : false}>
                                    <MenuItem onClick={handleStatusChangeClick}>{status === 'active' ? 'Deactivate' : 'Activate'}</MenuItem>
                                </AbilityCan>
                                <AbilityCan I='plans_update' passThrough={(user.role_slug === 'developer') ? true : false}>
                                    <MenuItem onClick={handleUpdateClick}>{`Update Plan`}</MenuItem>
                                </AbilityCan>
                                <AbilityCan I='plans_details' passThrough={(user.role_slug === 'developer') ? true : false}>
                                    <MenuItem onClick={handleDetailsClick}>{`Plan Details`}</MenuItem>
                                </AbilityCan>
                            </Menu>
                        </Box>

                    </Stack>
                </Box>

                <Stack spacing={2} direction="column" display={`flex`} alignItems={`center`} justifyContent={`center`} py={10}>
                    <Box style={{ minHeight: '80px' }}>
                        {discounted_cost && <Typography variant="h2" style={{ display: `flex`, alignItems: `center`, justifyContent: `center`, fontWeight: 500, textDecorationLine: 'line-through' }}><CurrencyRupee style={{ marginRight: '-5px' }} />{`${cost}`}</Typography>}
                        {discount && <Chip label={`${discount}% of discount`} size="small" />}
                    </Box>
                    <Typography variant="d1" style={{ display: `flex`, alignItems: `center`, justifyContent: `center`, fontWeight: 600 }}><CurrencyRupee style={{ marginRight: '-20px' }} />{`${discounted_cost ?? cost}`}</Typography>
                    {category !== 'giveaway' && <Chip label={`${number_of_coupons} coupons`} size="small" />}
                </Stack>

                <Divider />

                <Stack spacing={1}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`Category : `}</Typography>
                        <Chip label={`${category}`} style={{ textTransform: `capitalize` }} size="small" />
                    </Box>
                    {category === 'giveaway' && <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`Duration : `}</Typography>
                        <Chip label={`${duration} ${duration > '1' ? 'Days' : 'Day'}`} style={{ textTransform: `capitalize` }} size="small" />
                    </Box>}
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="body2">{`Created On : `}</Typography>
                        <Chip label={`${created_at}`} size="small" />
                    </Box>
                </Stack>
                <Divider />
                <Box style={{ minHeight: `125px` }}>
                    <Typography variant="body2">{`${description}`}</Typography>
                </Box>

            </CardContent>
        </PlanCardComponent>
    )
}

export { PlanCard }