import {
    BRANDS_REQUEST, BRANDS_SUCCESS, BRANDS_FAILURE,
    CHANGE_BRAND_STATUS_REQUEST, CHANGE_BRAND_STATUS_SUCCESS, CHANGE_BRAND_STATUS_FAILURE,
    UPDATE_BRAND_REQUEST, UPDATE_BRAND_SUCCESS, UPDATE_BRAND_FAILURE,
    BRANDS_SELECT_LIST_REQUEST, BRANDS_SELECT_LIST_SUCCESS, BRANDS_SELECT_LIST_FAILURE,
    BRAND_DETAILS_REQUEST, BRAND_DETAILS_SUCCESS, BRAND_DETAILS_FAILURE,
    BRAND_CSV_REQUEST, BRAND_CSV_SUCCESS, BRAND_CSV_FAILURE,
    BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_REQUEST, BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_SUCCESS, BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_FAILURE,
    DELETE_BRAND_PROFILE_REQUEST, DELETE_BRAND_PROFILE_SUCCESS, DELETE_BRAND_PROFILE_FAILURE,
    CHANGE_BRAND_PASSWORD_REQUEST, CHANGE_BRAND_PASSWORD_SUCCESS, CHANGE_BRAND_PASSWORD_FAILURE,

} from "../actions";

const initialState = {
    brands_loading: false, brands: [], brands_errors: {},
    brands_select_list_loading: false, brands_select_list: [], brands_select_list_errors: {},
    brand_details_loading: false, brand_details: {}, brand_details_errors: {},
    brand_csv_loading: false, brand_csv: [], brand_csv_errors: {},
    brand_send_influencer_welcome_message_loading: false, brand_send_influencer_welcome_message: false, brand_send_influencer_welcome_message_errors: {},
    delete_brand_profile_loading: false, delete_brand_profile_errors: {},
    change_brand_password_loading: false, change_brand_password: {}, change_brand_password_errors: [],
    
};

export function BrandReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List brand redux */
        case BRANDS_REQUEST: {
            return {
                ...state,
                brands_loading: true,
                brands_errors: {}
            };
        }
        case BRANDS_SUCCESS: {

            let newArray = [...state.brands]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.brands.forEach((payload) => {
                const brandIndex = newArray.findIndex(brands => brands._id === payload._id);
                if (brandIndex !== -1) {
                    newArray[brandIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                brands_loading: false,
                brands: newArray,
                brands_errors: {}
            };

        }
        case BRANDS_FAILURE: {
            return {
                ...state,
                brands_loading: false,
                brands_errors: action.errors,
                brands: [],
            };
        }
        /** end::List brand brand coupon codes redux */

        /** begin::Change brand status redux */
        case CHANGE_BRAND_STATUS_REQUEST: {
            return {
                ...state,
                change_brand_status_loading: true,
                change_brand_status_errors: {}
            };
        }
        case CHANGE_BRAND_STATUS_SUCCESS: {
            let brandArray = [...state.brands];
            let brandDetailObject = state.brand_details

            const brandIndex = brandArray.findIndex(brands => brands._id === action.data._id);

            if (brandIndex !== -1) {
                brandArray[brandIndex].status = action.data.status;
            }
            if (brandDetailObject.id === action.data.id) {
                brandDetailObject.status = action.data.status
            }

            return {
                ...state,
                change_brand_status_loading: false,
                change_brand_status_errors: {},
                brands: brandArray
            };
        }
        case CHANGE_BRAND_STATUS_FAILURE: {
            return {
                ...state,
                change_brand_status_loading: false,
                change_brand_status_errors: action.errors
            };
        }
        /** end::Change brands status redux */

        /** begin::Update brands redux */
        case UPDATE_BRAND_REQUEST: {
            return {
                ...state,
                update_brands_loading: true,
                update_brands_errors: {}
            };
        }
        case UPDATE_BRAND_SUCCESS: {
            let brandArray = [...state.brands];
            const brandIndex = brandArray.findIndex(brands => brands._id === action.data._id);
            if (brandIndex !== -1) {
                brandArray[brandIndex] = action.data;
            }

            return {
                ...state,
                brands: brandArray,
                update_brands_loading: false,
                update_brands: action.data,
                update_brands_errors: {}
            };
        }
        case UPDATE_BRAND_FAILURE: {
            return {
                ...state,
                update_brands_loading: false,
                update_brands_errors: action.errors
            };
        }
        /** end::Update brand redux */

        /** begin::Brands select list redux */
        case BRANDS_SELECT_LIST_REQUEST: {
            return {
                ...state,
                brands_select_list_loading: true,
                brands_select_list_errors: {}
            };
        }
        case BRANDS_SELECT_LIST_SUCCESS: {

            return {
                ...state,
                brands_select_list_loading: false,
                brands_select_list_errors: {},
                brands_select_list: action.data

            };

        }
        case BRANDS_SELECT_LIST_FAILURE: {
            return {
                ...state,
                brands_select_list_loading: false,
                brands_select_list_errors: action.errors,
                brands_select_list: []
            };
        }
        /** end::Brands select list redux */

        /** begin::brand details redux */
        case BRAND_DETAILS_REQUEST: {
            return {
                ...state,
                brand_details_loading: true,
                brand_details_errors: {}
            };
        }
        case BRAND_DETAILS_SUCCESS: {
            return {
                ...state,
                brand_details_loading: false,
                brand_details: action.data,
                brand_send_influencer_welcome_message: action.data.send_influencer_welcome_message,
                brand_details_errors: {}
            };
        }
        case BRAND_DETAILS_FAILURE: {
            return {
                ...state,
                brand_details_loading: false,
                brand_details_errors: action.errors
            };
        }
        /** end::brand details redux */

        /** begin::brand csv redux */
        case BRAND_CSV_REQUEST: {
            return {
                ...state,
                brand_csv_loading: true,
                brand_csv_errors: {}
            };
        }
        case BRAND_CSV_SUCCESS: {

            return {
                ...state,
                brand_csv_loading: false,
                brand_csv: action.data,
                brand_csv_errors: {}
            };
        }
        case BRAND_CSV_FAILURE: {
            return {
                ...state,
                brand_csv_loading: false,
                brand_csv: [],
                brand_csv_errors: action.errors
            };
        }
        /** end::brand csv redux */

        /** begin::brand send influencer message redux */
        case BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_REQUEST: {
            return {
                ...state,
                brand_send_influencer_welcome_message_loading: true,
                brand_send_influencer_welcome_message_errors: {}
            };
        }
        case BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_SUCCESS: {
            let brandDetailObject = state.brand_details

            if (brandDetailObject.id === action.data.id) {
                brandDetailObject.send_influencer_welcome_message = action.data.send_influencer_welcome_message
            }

            return {
                ...state,
                brand_send_influencer_welcome_message_loading: false,
                brand_send_influencer_welcome_message: action.data.send_influencer_welcome_message,
                brand_send_influencer_welcome_message_errors: {}
            };
        }
        case BRAND_SEND_INFLUENCER_WELCOME_MESSAGE_FAILURE: {
            return {
                ...state,
                brand_send_influencer_welcome_message_loading: false,
                brand_send_influencer_welcome_message: [],
                brand_send_influencer_welcome_message_errors: action.errors
            };
        }
        /** end::brand csv redux */

        /** begin::Change brand password redux */
        case CHANGE_BRAND_PASSWORD_REQUEST: {
            return {
                ...state,
                change_brand_password_loading: true,
                change_brand_password_errors: {}
            };
        }
        case CHANGE_BRAND_PASSWORD_SUCCESS: {
            return {
                ...state,
                change_brand_password_loading: false,
                change_brand_password: action.data,
                change_brand_password_errors: {}
            };
        }
        case CHANGE_BRAND_PASSWORD_FAILURE: {
            return {
                ...state,
                change_brand_password_loading: false,
                change_brand_password_errors: action.errors
            };
        }
        /** end::Change brand password redux */

        /** begin::delete brand redux */
        case DELETE_BRAND_PROFILE_REQUEST: {
            return {
                ...state,
                delete_brand_profile_loading: true,
                delete_brand_profile_errors: {}
            };
        }
        case DELETE_BRAND_PROFILE_SUCCESS: {

            return {
                ...state,
                delete_brand_profile_loading: false,
                delete_brand_profile_errors: {}
            };
        }
        case DELETE_BRAND_PROFILE_FAILURE: {
            return {
                ...state,
                delete_brand_profile_loading: false,
                delete_brand_profile_errors: action.errors
            };
        }
        /** end::delete brand redux */
        default:
            return state;
    }
}
