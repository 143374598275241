import { GlobalAction } from ".";
import { PermissionServices } from "../services";

export const ADD_PERMISSION_REQUEST = 'ADD_PERMISSION_REQUEST';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';
export const ADD_PERMISSION_FAILURE = 'ADD_PERMISSION_FAILURE';

export const UPDATE_PERMISSION_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_PERMISSION_FAILURE = 'UPDATE_USER_FAILURE';

export const CHANGE_PERMISSION_STATUS_REQUEST = 'CHANGE_PERMISSION_STATUS_REQUEST';
export const CHANGE_PERMISSION_STATUS_SUCCESS = 'CHANGE_PERMISSION_STATUS_SUCCESS';
export const CHANGE_PERMISSION_STATUS_FAILURE = 'CHANGE_PERMISSION_STATUS_FAILURE';

export const PERMISSIONS_SELECT_LIST_REQUEST = 'PERMISSIONS_SELECT_LIST_REQUEST';
export const PERMISSIONS_SELECT_LIST_SUCCESS = 'PERMISSIONS_SELECT_LIST_SUCCESS';
export const PERMISSIONS_SELECT_LIST_FAILURE = 'PERMISSIONS_SELECT_LIST_FAILURE';

export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';

export const PermissionAction = {

    /**
     * Action to add Permission 
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 15 June 2022
     */
    addPermission: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PermissionServices.addPermission(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_PERMISSION_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_PERMISSION_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_PERMISSION_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to update Permission 
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 15 June 2022
     */
    updatePermission: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PermissionServices.updatePermission(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_PERMISSION_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_PERMISSION_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_PERMISSION_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to change Permission status 
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 15 June 2022
     */
    changePermissionStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PermissionServices.changePermissionStatus(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_PERMISSION_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_PERMISSION_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_PERMISSION_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for permission select list
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
    */
    permissionSelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PermissionServices.permissionSelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: PERMISSIONS_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: PERMISSIONS_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PERMISSIONS_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action get Permission list
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 15 June 2022
     */
    getPermissionsList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PermissionServices.getPermissionsList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_PERMISSIONS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_PERMISSIONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_PERMISSIONS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },


};



