import { RoleServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const ROLE_REQUEST = 'ROLE_REQUEST';
export const ROLE_SUCCESS = 'ROLE_SUCCESS';
export const ROLE_FAILURE = 'ROLE_FAILURE';

export const ROLE_CREATE_REQUEST = 'ROLE_CREATE_REQUEST';
export const ROLE_CREATE_SUCCESS = 'ROLE_CREATE_SUCCESS';
export const ROLE_CREATE_FAILURE = 'ROLE_CREATE_FAILURE';

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';

export const CHANGE_ROLE_STATUS_REQUEST = 'CHANGE_ROLE_STATUS_REQUEST';
export const CHANGE_ROLE_STATUS_SUCCESS = 'CHANGE_ROLE_STATUS_SUCCESS';
export const CHANGE_ROLE_STATUS_FAILURE = 'CHANGE_ROLE_STATUS_FAILURE';

export const ROLES_SELECT_LIST_REQUEST = 'ROLES_SELECT_LIST_REQUEST';
export const ROLES_SELECT_LIST_SUCCESS = 'ROLES_SELECT_LIST_SUCCESS';
export const ROLES_SELECT_LIST_FAILURE = 'ROLES_SELECT_LIST_FAILURE';

export const ASSIGN_PERMISSION_REQUEST = 'ASSIGN_PERMISSION_REQUEST';
export const ASSIGN_PERMISSION_SUCCESS = 'ASSIGN_PERMISSION_SUCCESS';
export const ASSIGN_PERMISSION_FAILURE = 'ASSIGN_PERMISSION_FAILURE';

export const GET_ASSIGNED_PERMISSIONS_REQUEST = 'GET_ASSIGNED_PERMISSIONS_REQUEST';
export const GET_ASSIGNED_PERMISSIONS_SUCCESS = 'GET_ASSIGNED_PERMISSIONS_SUCCESS';
export const GET_ASSIGNED_PERMISSIONS_FAILURE = 'GET_ASSIGNED_PERMISSIONS_FAILURE';

export const RoleAction = {

    /**
     * Action list all Roles
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 15 June 2022
     */
    listRoles: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.listRole(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: ROLE_REQUEST, request: request } }
        function success(request, response) { return { type: ROLE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ROLE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Creating Role
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    createRole: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.createRole(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        }

        function request(request) { return { type: ROLE_CREATE_REQUEST, request: request } }
        function success(request, response) { return { type: ROLE_CREATE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ROLE_CREATE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for change role status
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_ROLE_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_ROLE_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_ROLE_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for update role
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
    */
    UpdateRole: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.updateRole(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_ROLE_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_ROLE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_ROLE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for role select list
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
    */
    roleSelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.roleSelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ROLES_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: ROLES_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ROLES_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    
    /**
     * Action to assign permissions
    */
    assignPermission: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.assignPermission(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ASSIGN_PERMISSION_REQUEST, request: request } }
        function success(request, response) { return { type: ASSIGN_PERMISSION_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ASSIGN_PERMISSION_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /*
     * Action to get assigned permissions
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
    */
    getAssignedPermissions: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.getAssignedPermissions(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_ASSIGNED_PERMISSIONS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_ASSIGNED_PERMISSIONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_ASSIGNED_PERMISSIONS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
}