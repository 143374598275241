import { Home, LocalOffer, Notifications, Password, Person, RowingOutlined, Warning, BrandingWatermark, AttachMoney, People, Storefront, Approval, CreditScore, DynamicFeed, Mail, PeopleAlt, Key, AdminPanelSettings, SupportAgent, Chat, QuestionAnswer, LocalActivity, Subscriptions, CardMembership } from "@mui/icons-material";
import CampaignIcon from '@mui/icons-material/Campaign';
import { Advertisement, CreateAdvertisement } from "../app/advertisements";
import { Brands, BrandDetails, BrandUpdate } from "../app/brands";
import { Dashboard } from "../app/dashboard";
import { Influencers, InfluencerDetails } from "../app/influencer";
import { AccountStatus, ChangePassword, Profile, ProfileDetails, Notifications as NotificationList, ActivityLog } from "../app/profile";
import { Invoice } from "../app/invoice";
import { CreateOrUpdatePromo, PromoDetails, Promos } from "../app/promos";
import { StoriesDetail, Story } from "../app/story";
import { Testimonial, CreateTestimonial } from "../app/testimonial";
import { EmailLogDetails, EmailLogs } from "../app/email-logs";
import { Role, CreateRole, AssignPermission } from "../app/role";
import { Users } from "../app/users";
import { CreateUser } from "../app/users/user-create";
import { Permission, CreatePermission } from "../app/permission";
import { SupportCenter, SupportCenterDetails } from "../app/support-center";
import { ContactUs, ContactUsDetails } from '../app/contact-us';
import { BrandCost, CreateBrandCost } from "../app/brand-cost";
import { CreatePlan, PlanDetails, PlanInstallationCost, Plans } from "../app/plans";
import { Approvals, ApprovalsAction } from "../app/approvals";
import { Faq, CreateFaq, SortFaq, FaqDetails } from '../app/faq';
import { CreateOrUpdateGiveaway, GiveawayDetails, Giveaways } from "../app/giveaways";
import { NewsletterSubscribers } from "../app/newsletter-subscribers";


const allRoutes = {

    /** begin::home module */
    'home': {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Home,
        module: 'dashboard',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'dashboard'
    },
    /** end::home module */

    /** begin::profile module  */
    'profile': {
        path: "/profile/:type?",
        name: "Profile",
        component: Profile,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenu: true,
        isModuleMenuItem: false,
        isMainComponent: true
    },
    'profile_details': {
        path: "/profile/details",
        name: "Profile",
        component: ProfileDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenuItem: true,

    },
    /** end::profile module */

    /** begin::notifications module */
    'notifications': {
        path: "/profile/notifications",
        name: "Notifications",
        component: NotificationList,
        layout: "/post-auth",
        menuIcon: Notifications,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::notifications module */

    /** begin::activity log module */
    'activity_log': {
        path: "/profile/activity",
        name: "Activity Log",
        component: ActivityLog,
        layout: "/post-auth",
        menuIcon: RowingOutlined,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::activity log module */

    /** begin::change password  module */
    'change_password': {
        path: "/profile/change-password",
        name: "Change Password",
        component: ChangePassword,
        layout: "/post-auth",
        menuIcon: Password,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::change password module */

    /** begin::account status module */
    'account_status': {
        path: "/profile/status",
        name: "Account Status",
        component: AccountStatus,
        layout: "/post-auth",
        menuIcon: Warning,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::account status module */

    /** begin::influencer module */
    'influencer': {
        path: "/influencers",
        name: "Influencers",
        component: Influencers,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: People,
        module: 'influencer',
        isModuleMenuItem: true,
        isMainComponent: true,
        hasSeparation: true,
        menuPermission: 'influencers'
    },
    'influencer_details': {
        path: "/influencers/details",
        name: "Influencer Details",
        component: InfluencerDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: People,
        module: 'influencer',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'influencer_details'
    },
    /** end::influencer module */

    /** begin::brands module */
    'brands': {
        path: "/brands",
        name: "Brands",
        component: Brands,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Storefront,
        module: 'brands',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'brands'
    },
    'brand_details': {
        path: "/brands/details",
        name: "Brand Details",
        component: BrandDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: BrandingWatermark,
        module: 'brands',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'update_brands': {
        path: "/brands/update",
        name: "Update Brands",
        component: BrandUpdate,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: BrandingWatermark,
        module: 'brands',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'add_update_brand'
    },
    'brand_promos': {
        path: "/brands/promos",
        name: "Promos",
        component: Promos,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'brands',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    // 'update_promos': {
    //     path: "/brands/promos/update",
    //     name: "Update Promos",
    //     component: CreatePromos,
    //     layout: "/post-auth",
    //     isMenuItem: false,
    //     menuIcon: LocalOffer,
    //     module: 'brands',
    //     isModuleMenuItem: true,
    //     isMainComponent: true
    // },
    /** end::brands module */

    /** begin::promo module */
    'promo': {
        path: "/promos",
        name: "Promos",
        component: Promos,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: LocalOffer,
        module: 'promos',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'promos'
    },
    'promo_details': {
        path: "/promos/details",
        name: "Promo Details",
        component: PromoDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: BrandingWatermark,
        module: 'promos',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'promo_details'
    },

    'create_promos': {
        path: "/promos/create",
        name: "Create Promo",
        component: CreateOrUpdatePromo,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'promos',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'create_promo'
    },
    'update_promos': {
        path: "/promos/update",
        name: "Update Promo",
        component: CreateOrUpdatePromo,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'promos',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_promo'
    },
    /** end::promo module */

    /** begin::giveaways module */
    'giveaways': {
        path: "/giveaways",
        name: "Giveaways",
        component: Giveaways,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: LocalActivity,
        module: 'giveaways',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'giveaways'
    },
    'giveaway_details': {
        path: "/giveaways/details",
        name: "Giveaway Details",
        component: GiveawayDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalActivity,
        module: 'giveaways',
        isModuleMenuItem: true,
        isMainComponent: true
    },

    'create_giveaways': {
        path: "/giveaways/create",
        name: "Create Giveaway",
        component: CreateOrUpdateGiveaway,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalActivity,
        module: 'giveaways',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'create_giveaway'
    },
    'update_giveaways': {
        path: "/giveaways/update",
        name: "Update Giveaway",
        component: CreateOrUpdateGiveaway,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalActivity,
        module: 'giveaways',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_giveaway'
    },
    /** end::giveaways module */

    /** begin::stories module */
    'story': {
        path: "/stories",
        name: "Stories",
        component: Story,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: DynamicFeed,
        module: 'story',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'stories'

    },
    'stories_detail': {
        path: "/stories/detail",
        name: "Stories Detail",
        component: StoriesDetail,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: DynamicFeed,
        module: 'stories',
        isModuleMenuItem: false,
        isMainComponent: true,
        menuPermission: 'stories_details'
    },
    /** end::stories module */

    /** begin::approvals module */
    'approvals': {
        path: "/approvals",
        name: "Approvals",
        component: Approvals,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Approval,
        module: 'approvals',
        isModuleMenuItem: true,
        isMainComponent: true,
        hasSeparation: true,
        menuPermission: 'approvals'
    },
    'approvals_action': {
        path: "/approvals/action",
        name: "Approvals Action",
        component: ApprovalsAction,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Approval,
        module: 'approvals',
        isModuleMenuItem: false,
        isMainComponent: true,
        menuPermission: 'approval_action'
    },
    /** end::approvals module */

    /** begin::invoice module */
    'invoice': {
        path: "/invoice",
        name: "Invoice",
        component: Invoice,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: CreditScore,
        module: 'invoice',
        isModuleMenuItem: true,
        isMainComponent: true,
        hasSeparation: true,
        menuPermission: 'invoice'
    },
    /** end::invoice module */

    /** begin::brand cost module */
    'brand_cost': {
        path: "/brand-cost",
        name: "Brand Cost",
        component: BrandCost,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: CardMembership,
        module: 'brand_cost',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'brand_cost'
    },
    'create_brand_cost': {
        path: "/brand-cost/create",
        name: "Create Brand Cost",
        component: CreateBrandCost,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: CardMembership,
        module: 'brand_cost',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'update_brand_cost': {
        path: "/brand-cost/update",
        name: "Update Brand Cost",
        component: CreateBrandCost,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: CardMembership,
        module: 'brand_cost',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_brand_cost'
    },
    /** end::brands module */


    /** begin::email log module */
    'email_log': {
        path: "/email-log",
        name: "Email Logs",
        component: EmailLogs,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Mail,
        module: 'email_log',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'email_log'
    },
    'email_log_details': {
        path: "/email-log/details",
        name: "Email Log Details",
        component: EmailLogDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Mail,
        module: 'email_log',
        isModuleMenuItem: false,
        isMainComponent: true,
        menuPermission: 'email_log_details'
    },
    /** end::email log module */

    /** begin::plans module */
    'plans': {
        path: "/plans",
        name: "Plans",
        component: Plans,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: AttachMoney,
        module: 'plans',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'plans'
    },
    'plan_details': {
        path: "/plans/details",
        name: "Plan Details",
        component: PlanDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AttachMoney,
        module: 'plans',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'plans_details'
    },
    'create_plans': {
        path: "/plans/create",
        name: "Create Plan",
        component: CreatePlan,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AttachMoney,
        module: 'plans',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'plans_create'
    },
    'update_plans': {
        path: "/plans/update",
        name: "Update Plan",
        component: CreatePlan,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AttachMoney,
        module: 'plans',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'plans_update'
    },
    'create_installation_cost': {
        path: "/plans/installation-cost/create",
        name: "Create Installation Cost",
        component: PlanInstallationCost,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AttachMoney,
        module: 'plans',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'plans_installation_cost'
    },
    'update_installation_cost': {
        path: "/plans/installation-cost/update",
        name: "Update Installation Cost",
        component: PlanInstallationCost,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AttachMoney,
        module: 'plans',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    /** end::plans module */

    /** begin::newsletter subscribers module */
    'newsletter_subscribers': {
        path: "/newsletter-subscribers",
        name: "Newsletter Subscribers",
        component: NewsletterSubscribers,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Subscriptions,
        module: 'newsletter_subscribers',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'newsletter_subscribers'
    },
    /** begin::newsletter subscribers module */

    /** begin::user module */
    'user': {
        path: "/user",
        name: "Users",
        component: Users,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: PeopleAlt,
        module: 'users',
        isModuleMenuItem: true,
        isMainComponent: true,
        hasSeparation: true,
        menuPermission: 'users'
    },
    'create_user': {
        path: "/user/create",
        name: "Create User",
        component: CreateUser,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: PeopleAlt,
        module: 'users',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'users_create'
    },
    'update_user': {
        path: "/user/update",
        name: "Update User",
        component: CreateUser,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: PeopleAlt,
        module: 'users',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_user'
    },
    /** end::user module */

    /** begin::role module */
    'role': {
        path: "/role",
        name: "Role",
        component: Role,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'roles'
    },
    'create_role': {
        path: "/role/create",
        name: "Create Role",
        component: CreateRole,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'role_create'
    },
    'update_role': {
        path: "/role/update",
        name: "Update Role",
        component: CreateRole,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_role'
    },
    'assign_permission': {
        path: "/role/assign-permission",
        name: "Assign Permission",
        component: AssignPermission,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'role_assign_permission'
    },
    /** end::role module */

    /** begin::permission module */
    'permission': {
        path: "/permission",
        name: "Permissions",
        component: Permission,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Key,
        module: 'permissions',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'permissions'
    },
    'create_permission': {
        path: "/permission/create",
        name: "Create Permission",
        component: CreatePermission,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Key,
        module: 'permissions',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'permission_create'
    },
    'update_permission': {
        path: "/permission/update",
        name: "Update Permission",
        component: CreatePermission,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Key,
        module: 'permissions',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_permission'
    },
    /** end::permission module */
    /** begin::testimonial module */
    'testimonial': {
        path: "/testimonial",
        name: "Testimonials",
        component: Testimonial,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Approval,
        module: 'testimonial',
        isModuleMenuItem: true,
        isMainComponent: true,
        hasSeparation: true,
        menuPermission: 'testimonials'
    },
    'create_testimonial': {
        path: "/testimonial/create",
        name: "Create Testimonial",
        component: CreateTestimonial,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AttachMoney,
        module: 'testimonial',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'testimonials_create'
    },
    /** end::testimonial module */

    /** begin::advertisement module */
    'advertisement': {
        path: "/advertisement",
        name: "Advertisement",
        component: Advertisement,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: CampaignIcon,
        module: 'advertisement',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'advertisements'
    },
    'create_advertisement': {
        path: "/advertisement/create",
        name: "Create Advertisement",
        component: CreateAdvertisement,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: CampaignIcon,
        module: 'advertisement',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'advertisement_create'
    },
    'update_advertisement': {
        path: "/advertisement/update",
        name: "Update Advertisement",
        component: CreateAdvertisement,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: CampaignIcon,
        module: 'advertisement',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_advertisement'
    },
    /** end::advertisement module */
    'contact_us': {
        path: '/contact-us',
        name: "Contact Us",
        component: ContactUs,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Chat,
        module: "contact-us",
        isModuleMenuItem: true,
        isMainComponent: true,
        hasSeparation: true,
        menuPermission: 'contact_us'
    },
    'contact_us_details': {
        path: "/contact-us/details",
        name: "Contact Us Details",
        component: ContactUsDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Chat,
        module: 'contact-us',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'contact_us_details'

    },
    'support_center': {
        path: "/support-center",
        name: "Support Center",
        component: SupportCenter,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: SupportAgent,
        module: 'support-Center',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'support_center'
    },
    'support_center_details': {
        path: "/support-center/details",
        name: "Support Center Details",
        component: SupportCenterDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: SupportAgent,
        module: 'support-Center',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'support_center_details'

    },
    /** begin::faq module */
    'faq': {
        path: "/faq",
        name: "FAQ",
        component: Faq,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: QuestionAnswer,
        module: 'faq',
        isModuleMenuItem: true,
        isMainComponent: true,
        hasSeparation: true,
        menuPermission: 'faq'
    },
    'create_faq': {
        path: "/faq/create",
        name: "Add FAQ",
        component: CreateFaq,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: QuestionAnswer,
        module: 'faq',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'faq_create'
    },
    'update_faq': {
        path: "/faq/update",
        name: "Update FAQ",
        component: CreateFaq,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: QuestionAnswer,
        module: 'faq',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'faq_update'
    },
    'sort_faq': {
        path: "/faq/sort",
        name: "Sort FAQ",
        component: SortFaq,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: QuestionAnswer,
        module: 'faq',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'faq_sort'
    },
    'faq_details': {
        path: "/faq/details",
        name: "FAQ Details",
        component: FaqDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: QuestionAnswer,
        module: 'faq',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'faq_details'
    },
    /** end::faq module */

};

function PostAuthRoutes(keyType = null) {
    if (keyType) {
        return allRoutes[keyType];
    }
}

function PostAuthMenuRoutes() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMenuItem === true;
    });
}

function PostAuthMainComponents() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMainComponent === true ? (
            { ...item, path: (item.isModuleMenu) ? (item.path.replace(':type?', '/*')) : (item.path) }
        ) : null;
    });
}

function PostAuthModuleMenuRoutes(module) {
    return Object.values(allRoutes).filter(function (item) {
        return (item.isModuleMenuItem === true && item.module === module) ? ({
            ...item, path: item.path.split("/").pop()
        }) : null;
    });
}

function getPostComponentNameByPath(path) {
    if (path) {
        let componentItem = Object.values(allRoutes).filter(function (item) {
            return item.path === path;
        });
        return componentItem.length > 0 ? componentItem[0]['name'] : '';
    }
}


export { PostAuthRoutes, PostAuthMenuRoutes, PostAuthModuleMenuRoutes, PostAuthMainComponents, getPostComponentNameByPath };