import { apiServices, cookieServices } from '../../_helpers';


export const UserServices = {

    /**
     * Service for get user details
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     * @created_at 15 June 2022
     */
    getUserDetails: () => {
        return apiServices.get('/admin')
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('isDarkMode', response.data.is_dark_mode, options);
                return response;
            })
    },
    /**
     * Service for delete account
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     * @created_at 15 June 2022
     */
    deleteAccount: () => {
        return apiServices.put('/admin/delete')
            .then((response) => { cookieServices.remove('accessToken'); return response; })
    },
    /**
     * Service for change password
     * @param {Object} params - The params which are used for change password api.
     * @param {string} params.current_password - Current password of the admin user
     * @param {string} params.new_password - New password the admin user
     * @param {string} params.confirm_password - Password re-entered by the admin user
     * @author Akshay 
     * @created_at 15 June 2022
     */
    changePassword: (params) => {

        return apiServices.put('/admin/change-password', params)
            .then((response) => { cookieServices.remove('accessToken'); return response; })
    },

    /**
     * Service to get login activity list
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    getActivityList: (params) => {
        return apiServices.post('/admin/activity-log/list', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get user list
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    getUsersList: (params) => {
        return apiServices.post('/admin/list', params)
            .then((response) => { return response; })
    },

    /**
     * Service to add user
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    addUser: (params) => {
        return apiServices.post('/admin/add', params)
            .then((response) => { return response; })
    },

    /**
     * Service to update user
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    updateUser: (params) => {
        return apiServices.put('/admin/update', params)
            .then((response) => { return response; })
    },
    
    /**
     * Service to change user status
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    changeUserStatus: (params) => {
        return apiServices.put('/admin/change-status', params)
            .then((response) => { return response; })
    },

    /**
     * Service for get user details
     * @author Akshay 
     */
    getUserPermission: () => {
        return apiServices.get('/admin/admin-permission')
            .then((response) => { return response; })
    },

    /**
     * Service to update theme
     *
     * @author Akshay 
     */
    updateTheme: () => {
        return apiServices.put('/admin/update/theme')
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('isDarkMode', response.data.is_dark_mode, options);
                return response;
            })
    },
};
