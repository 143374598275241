import { apiServices } from '../../_helpers';


export const InfluencerServices = {

    /**
     * Service for list influencer
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
     */
    listInfluencer: (params) => {
        return apiServices.post('/admin/influencer', params)
            .then((response) => { return response; })

    },

    /**
     * Service for change influencer status
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
    */
    changeStatus: (params) => {
        return apiServices.put('/admin/influencer/change-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service to fetch influencer details
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
    */
    influencerDetails: (params) => {
        return apiServices.post('admin/influencer/details', params)
            .then((response) => { return response; })
    },

    /**
     * Service for Influencer select list
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
    */
    influencerSelectList: (params) => {
        return apiServices.post('admin/influencer/influencer-select-list', params)
            .then((response) => { return response; })
    },

    /**
     * Service to export influencer CSV
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 April 2022
    */
    exportInfluencerCsv: (params) => {
        return apiServices.post('/admin/influencer/export-csv', params)
            .then((response) => { return response; })

    },

}