import { apiServices } from '../../_helpers';


export const WalletServices = {

    /**
     * Service to create wallet
     * @param {Object} params - The params which are used for change password api
     * @author Jasin N
     * @created_at 30 May 2022
     */
    createBrandWallet: (params) => {
        return apiServices.post('/admin/wallet/add', params)
            .then((response) => { return response; })

    },

    /**
     * Service for wallet history
     * @author Naveen
     * @created_at 09 May 2023
     */
    listWalletHistory: (params) => {
        return apiServices.post('/admin/wallet/history', params)
        .then((response) => { return response; })
    },

    /**
     * Service for total month in wallet
     * @author Naveen
     * @created_at 10 May 2023
     */
    totalWallet: () => {
        return apiServices.post('/admin/wallet/total')
        .then((response) => { return response; })
    },
}
