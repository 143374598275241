import React, { useEffect, useState } from 'react'
import queryString from 'query-string';
import { DeleteModal } from '../../_components/modal/modals/DeleteModal';
import { DataTable } from '../../_components/data-table/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { FaqAction } from '../../redux/actions';
import { Button, ButtonGroup, Chip } from '@mui/material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { useHistory } from 'react-router-dom';
import { PostAuthRoutes } from '../../routes';
import { Edit, Delete, Visibility } from '@mui/icons-material';
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

function FaqDataTable({ statusUpdateModal, limit, page, filter, search, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { per_page, total, faq_loading: isLoading, faq } = useSelector((state) => state.FaqReducer);
    const listFaq = (params) => dispatch(FaqAction.listFaq(params));
    const deleteFaq = (params) => dispatch(FaqAction.deleteFaq(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ filter, search, page, limit });

    const columns = [
        { name: '#', selector: (row, i) => (row._id), sortField: '_id', center: true, width: '4%', cell: (row, i) => ((per_page * (payload.page - 1)) + (i + 1)) },
        { name: 'Question', selector: (row, i) => (row.question), sortField: 'question', sortable: true, width: '66%', wrap: true },
        { name: 'Created By', selector: (row, i) => (row.created_by), sortField: 'created_by', sortable: true, width: '9%' },
        { name: 'Created Date', selector: (row, i) => (row.created_at), sortField: 'created_at', center: true, sortable: true, width: '11%' },
        { name: 'Status', selector: (row, i) => (row.status), sortField: 'status', center: true, sortable: true, width: '10%', cell: row => (<StatusRow row={row} />) },
    ];

    useEffect(() => {
        setPayload({ filter, search, page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, limit])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        history.push({
            pathname: PostAuthRoutes('faq').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search, payload.page])

    /**
     * functions on status rows
     * @param {Object} row
     * @return json
     * @author Naveen
     * @created_at 10 Jan 2023
     */
    const StatusRow = ({ row }) => {

        const [faqDeleteData, setFaqDeleteData] = useState({ id: '' });
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        /**
         * function to update data of the row
         * @param {object} row
         * @return json
         * @author Naveen
         * @created_at 10 Jan 2023
         */
        function handleUpdateClick(row) {
            history.push({
                pathname: PostAuthRoutes('create_faq').path,
                state: { faq: row }
            });
        }

        /**
         * function to open/close FAQ delete modal
         * @return view
         * @author Naveen
         * @created_at 10 Jan 2023
         */
        const faqDeleteModal = async (row) => {
            setFaqDeleteData({ id: row._id })
            setShowDeleteModal(!showDeleteModal)
        }

        /**
         * Function to delete FAQ 
         * @author Naveen
         * @created_at 10 Jan 2023 
         */
        const faqDeleteAction = async (id) => {
            setShowDeleteModal(!showDeleteModal)
            await deleteFaq({ 'faq_id': id });
        }

        /**
         * function to change status of the row
         * @param {object} row
         * @param {object} row._id -id of the row
         * @param {object} row.status -status of the row
         * @return json
         * @author 
         * @created_at 10 Jan 2023
         */
        function handleChangeStatusClick(row) {
            setTimeout(() => {
                statusUpdateModal({ id: row._id, status: row.status, item: '' });
            }, 100);
        }

        /**
         * function to display FAQ details of the row
         * @param {string} id - Id of the data which we need to display
         * @return json
         * @author Naveen
         * @created_at 13 Jan 2023
         */
        const faqDetails = (row) => {
            history.push({
                pathname: PostAuthRoutes('faq_details').path,
                search: `?fId=${row._id}`
            });
        }

        let status = <Chip label={row.status} className="badge-status" />;
        if (row.status === 'active')
            status = <Chip label={row.status[0].toUpperCase() + row.status.substring(1)} color="success" className="badge-status capitalize" />;
        else if (row.status === 'inactive')
            status = <Chip label={row.status[0].toUpperCase() + row.status.substring(1)} color="error" className="badge-status capitalize" />;

        let statusHover = (
            <ButtonGroup variant="text" size="small" className='row-action'>
                <AbilityCan I='faq_update' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Button onClick={() => handleUpdateClick(row)}> <Edit /></Button>
                </AbilityCan>
                <AbilityCan I='faq_delete' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Button onClick={() => faqDeleteModal(row)}> <Delete /></Button>
                </AbilityCan>
                <AbilityCan I='faq_change_status' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Button onClick={() => handleChangeStatusClick(row)}> <ChangeCircleIcon /></Button>
                </AbilityCan>
                <AbilityCan I='faq_details' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Button onClick={() => faqDetails(row)}> <Visibility /></Button>
                </AbilityCan>
            </ButtonGroup>
        )

        return (
            <React.Fragment>
                {status}{statusHover}
                <DeleteModal show={showDeleteModal} closeModal={faqDeleteModal} data={faqDeleteData} deleteModal={faqDeleteAction} />
            </React.Fragment>
        )

    }

    /**
     * Function to fetch faq list
     * @return json 
     * @author Naveen
     * @created_at 10 Jan 2023
     */
    const getData = async () => {
        await listFaq(payload);
    }

    /**
     * Function to sort columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * Function to handle page
     * @param {String} page - Page number to load 
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={faq}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSort={handleSort}
        />
    )

}

export { FaqDataTable };