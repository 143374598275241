import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";
import { FormInput } from "../../../_components/form";
import { Grid } from "@mui/material";
import { WalletAction } from "../../../redux/actions";
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from "../../../_components/modal";

// Initialize form input values to null
const inputs = { amount: '', brand_id: '' };

function CreateWallet({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    
    const params = new URLSearchParams(search);
    const brandId = params.get('brandId'); // story id from url

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    /** Initialize values from props */
    const { show, closeModal } = props;

    /** Redux actions and state */
    const { create_brand_wallet_loading: isLoading } = useSelector((state) => state.WalletReducer);
    const createBrandWallet = (params) => dispatch(WalletAction.createBrandWallet(params));

    /**
     * function to close wallet model
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const onClose = () => {
        closeModal(false);
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
        
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        data['brand_id'] = brandId;
        setData({ ...data });

        dispatchAction(formData);

    }

    const dispatchAction = async (formData) => {
        createBrandWallet(data).then((result) => {
            closeModal(false);
        });
    }

    return (
        <ConfzModal isDialogOpen={show} onClose={onClose} maxWidth='sm'>
            <form onSubmit={handleSubmit} noValidate >
                <ConfzModalHeader id="createWallet" onClose={onClose} title={`Add Money`} />
                <ConfzModalBody>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormInput
                                tabIndex={2}
                                label='Amount (RS)'
                                name='amount'
                                value={data.amount}
                                error={action.isSubmitted && errors.amount ? errors.amount : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <ConfzModalFooter justifyContent={'center'}>
                        <ConfzModalFooterSaveBtn tabIndex={4} text='Confirm' onClick={handleSubmit} loading={isLoading}/>
                        <ConfzModalFooterCloseBtn tabIndex={3} onClose={onClose} />
                    </ConfzModalFooter>
                </ConfzModalBody>
            </form>
        </ConfzModal>
    )
}

export { CreateWallet };
