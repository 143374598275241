import React, { useState, useEffect } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ContactUsAction } from '../../redux/actions/ContactUsAction';
import { Card, CardContent, CardHeader } from '../../_components/card';
import { PostAuthRoutes } from '../../routes';
import { GeneralButton } from '../../_components/controls/general-button/GeneralButton';
import { FilterAlt } from '@mui/icons-material';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { ContactUsDataTable } from './ContactUsDataTable';
import { ContactUsFilter } from './contact-us-filter';
import { UpdateStatusModal } from '.';
import { FormSearchInput } from '../../_components/form';
import { AbilityCan } from '../../_helpers/permission/AbilityCan';


function ContactUs(params) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    // const { contact_us_loading: isLoading, contact_us } = useSelector((state) => state.ContactUsReducer);
    const listContactUs = (params) => dispatch(ContactUsAction.listContactUS(params));
    const changeStatus = (params) => dispatch(ContactUsAction.changeStatus(params));

    /** Initialize and declare state */
    const [showContactUsFilterModal, setShowContactUsFilterModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [filterAction, setFilterAction] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: { status: searchParams.get('status') ?? '', date: searchParams.get('date') ?? '' },
        search: searchParams.get('search') ?? ''
    });

    useEffect(() => {
        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = payload.page;
        if (payload.search) queryParam.search = payload.search; else delete queryParam.search;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
        if (payload.filter.date) queryParam.date = payload.filter.date; else delete queryParam.date;
        let pageUrl = `${PostAuthRoutes('contact_us').path}?${queryString.stringify(queryParam)}`;

        window.history.pushState({}, '', pageUrl);
        getNext();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
     * function to list ContactUs
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNext = async () => {
        const result = await listContactUs(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    /**
     * function to open/close ContactUs filter modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const contactUsFilterModal = () => {
        setShowContactUsFilterModal(!showContactUsFilterModal);
    }

    /**
     * function to open/close ContactUs status update modal
     * @param {String} id - Id of the data
     * @param {String} status - status of the data
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to Update ContactUs status
     * @param {String} id - Id of the data 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'contactUs_id': id })
    }

    /**
     * function to apply the selected filter options on the contactUs list
     * @param {Object} filterData 
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setFilterAction(false);
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to apply the search key words on the ContactUs list
     * @param {String} text Search keyword used
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }
    return (
        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('contact_us').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            <AbilityCan I='contact_us_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            </AbilityCan>
                            <AbilityCan I='contact_us_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <GeneralButton label={<FilterAlt />} onClick={() => contactUsFilterModal()} />
                            </AbilityCan>
                        </Stack>
                    }
                />
                <ContactUsFilter show={showContactUsFilterModal} applyFilter={applyFilter} closeModal={contactUsFilterModal} contactUsFilter={payload.filter} />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card>
                            <CardContent>
                                <ContactUsDataTable statusUpdateModal={statusUpdateModal} filterData={payload.filter} filterAction={filterAction} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
        </React.Fragment>
    )
}

export { ContactUs }