import { InvoiceServices } from "../services";

export const GENERATE_INVOICE_REQUEST = 'GENERATE_INVOICE_REQUEST';
export const GENERATE_INVOICE_SUCCESS = 'GENERATE_INVOICE_SUCCESS';
export const GENERATE_INVOICE_FAILURE = 'GENERATE_INVOICE_FAILURE';

export const BRAND_LAST_INVOICE_REQUEST = 'BRAND_LAST_INVOICE_REQUEST';
export const BRAND_LAST_INVOICE_SUCCESS = 'BRAND_LAST_INVOICE_SUCCESS';
export const BRAND_LAST_INVOICE_FAILURE = 'BRAND_LAST_INVOICE_FAILURE';

export const INVOICE_REQUEST = 'INVOICE_REQUEST';
export const INVOICE_SUCCESS = 'INVOICE_SUCCESS';
export const INVOICE_FAILURE = 'INVOICE_FAILURE';

export const InvoiceAction = {

    /**
     * function to generate invoice
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    generateInvoice: (params) => {
        return InvoiceServices.generateInvoice(params)
            .then(
                response => { return response; },
                error => { return error; }
            );
    },

    /**
     * Action to get brand last invoice details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.request_id
     * @author Jasin 
     * @created_at 15 June 2022
    */
    brandLastInvoices: (params) => {
        return dispatch => {
            dispatch(request(params));
            return InvoiceServices.brandLastInvoices(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BRAND_LAST_INVOICE_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_LAST_INVOICE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_LAST_INVOICE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action list all invoices
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 June 2022
     */
    listInvoices: (params) => {
        return dispatch => {
            dispatch(request(params));
            return InvoiceServices.listInvoices(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: INVOICE_REQUEST, request: request } }
        function success(request, response) { return { type: INVOICE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: INVOICE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};
