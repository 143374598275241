import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Stack, Box } from '@mui/material'
import { CardHeader } from "../../../../_components/card";
import { GeneralButton } from "../../../../_components/controls/general-button/GeneralButton";
import { FilterAlt } from "@mui/icons-material";
import { useHistory, useLocation } from 'react-router-dom'
import { FormSearchInput } from '../../../../_components/form';
import queryString from 'query-string';
import { NoData } from "../../../../_components/layout/no-data";
import { AbilityCan } from "../../../../_helpers/permission/AbilityCan";
import { PromoCard, PromoCardLoader, PromoFilter } from "../../../promos";
import { PromoAction } from "../../../../redux/actions";
import { UpdateFeatureModal, UpdateStatusModal } from "../../../../_components/modal";

function BrandPromos() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(search);
    const brandId = searchParams.get('brandId'); // promo id from url

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { promos_loading: isLoading, promos } = useSelector((state) => state.PromoReducer);
    const listBrandPromos = (params) => dispatch(PromoAction.listBrandPromos(params));
    const changePromoStatus = (params) => dispatch(PromoAction.changeStatus(params));
    const changePromoFeature = (params) => dispatch(PromoAction.changePromoFeature(params));

    /** Initialize and declare state */
    const [showPromoFilterModal, setShowPromoFilterModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [showPromoStatusUpdateModal, setShowPromoStatusUpdateModal] = useState(false);
    const [showFeatureUpdateModal, setShowFeatureUpdateModal] = useState(false);
    const [featureData, setFeatureData] = useState({ id: '', feature: '' });

    const [payload, setPayload] = useState({
            brand_id: brandId,
            limit: 100, page: 1, filter: {
            status: searchParams.get('status') ?? '',
            date: searchParams.get('date') ?? '',
            feature: searchParams.get('feature') ?? ''
        },
        search: searchParams.get('q') ?? ''
    });

    useEffect(() => {
        getNext()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = payload.page;
        queryParam.brandId = brandId;
        queryParam.bdTab = 'promos';
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
        if (payload.filter.date) queryParam.date = payload.filter.date; else delete queryParam.date;
        if (payload.filter.influencer) queryParam.influencer = payload.filter.influencer; else delete queryParam.influencer;

        history.push({ search: `?${queryString.stringify(queryParam)}` })

        getNext() // Initially load participants list

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId, payload.filter, payload.search])

    /**
     * function to list Story
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNext = async () => {

        const result = await listBrandPromos(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    /**
     * function to apply the selected filter options on the Story list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the Story filter modal
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to apply the search key words on the Story list
     * @param {String} text Search keyword used
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    /**
    * function to open/close Promo status Update modal 
    * @return view
    * @author Naveen
    * @created_at 14 June 2022
    */
    const statusPromoUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowPromoStatusUpdateModal(!showPromoStatusUpdateModal);
    }

    
    /**
     * function to open/close Promo filter modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const promoFilterModal = () => {
        setShowPromoFilterModal(!showPromoFilterModal);
    }

    /**
     * function to update Brand Promo status
     * @return view
     * @author Naveen
     * @created_at 14 June 2022
     */
    const updatePromoStatusAction = async (id) => {
        setShowPromoStatusUpdateModal(!showPromoStatusUpdateModal);
        await changePromoStatus({ 'promo_id': id });
    }

    /**
     * function to open/close feature Update modal 
     * @return view
     * @author Naveen
     * @created_at 20 July 2022
     */
    const featureUpdateModal = ({ id, feature }) => {
        setFeatureData({ id: id, feature: feature });
        setShowFeatureUpdateModal(!showFeatureUpdateModal);
    }

    /**
     * function to update Promo feature
     * @return view
     * @author Naveen
     * @created_at 20 July 2022
     */
    const updateFeatureAction = async (id) => {
        setShowFeatureUpdateModal(!showFeatureUpdateModal);
        await changePromoFeature({ 'promo_id': id });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='promo_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='promo_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<FilterAlt />} onClick={() => promoFilterModal()} />
                        </AbilityCan>
                    </Stack>
                } />
                <PromoFilter show={showPromoFilterModal} applyFilter={applyFilter} closeModal={promoFilterModal} promoFilter={payload.filter} />
                <InfiniteScroll dataLength={promos.length} next={getNext} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                        {
                            promos.length > 0 && promos.map((promo, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={i}>
                                        <PromoCard promo={promo} statusUpdateModal={statusPromoUpdateModal} featureUpdateModal={featureUpdateModal} />
                                    </Grid>
                                );
                            })
                        }
                        {isLoading && <PromoCardLoader />}
                        {!isLoading && promos.length === 0 && <NoData content1={`No`} content2={`Promo available`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`55vh`} ></NoData>}
                    </Grid>
                </InfiniteScroll>
            </Box>
            <UpdateStatusModal show={showPromoStatusUpdateModal} closeModal={statusPromoUpdateModal} data={statusData} updateStatus={updatePromoStatusAction} />
            <UpdateFeatureModal show={showFeatureUpdateModal} closeModal={featureUpdateModal} data={featureData} updateFeature={updateFeatureAction} />
            
        </React.Fragment>
    )
}

export { BrandPromos }