export const APP_NAME = process.env.REACT_APP_APP_NAME;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const POST_BROKEN_IMAGE = process.env.REACT_APP_POST_BROKEN_IMAGE;
export const AVATAR_BROKEN_IMAGE = process.env.REACT_APP_AVATAR_BROKEN_IMAGE;

export const API_REQUEST_TIMEOUT = 10000;

export const URL_PRIVACY_POLICY = 'https://confyans.com';
export const URL_TERMS_OF_SERVICE = 'https://confyans.com';

export const DRAWER_WIDTH = 240;