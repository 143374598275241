import { apiServices } from '../../_helpers';


export const PlanServices = {

    /**
     * Service to list plans
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 15 April 2022
     */
    listPlans: (params) => {
        return apiServices.post('/admin/plan/list', params)
            .then((response) => { return response; })

    },

    /**
     * Service to create plan
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.name
     * @param {String} req.body.number_of_coupons
     * @param {String} req.body.description
     * @param {String} req.body.cost
     * @param {String} req.body.category
     * @author Jasin 
     * @created_at 15 April 2022
    */
    createPlan: (params) => {
        return apiServices.post('admin/plan/create', params)
            .then((response) => { return response; })
    },

    /**
     * Service to update plan
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.plan_id
     * @param {String} req.body.name
     * @param {String} req.body.number_of_coupons
     * @param {String} req.body.description
     * @param {String} req.body.cost
     * @param {String} req.body.category
     * @author Jasin 
     * @created_at 15 April 2022
    */
    updatePlan: (params) => {
        return apiServices.put('admin/plan/update', params)
            .then((response) => { return response; })
    },

    /**
     * Service to update plan
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.plan_id
     * @author Jasin 
     * @created_at 15 April 2022
    */
    planDetails: (params) => {
        return apiServices.post('admin/plan/details', params)
            .then((response) => { return response; })
    },

    /**
     * Service for change plan status
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
    */
    changeStatus: (params) => {
        return apiServices.put('/admin/plan/change-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service for list giveaway plans
     *
     * @author Akshay 
     */
    listGiveawayPlans: (params) => {
        return apiServices.post('/admin/plan/giveaway/list', params)
        .then((response) => { return response; })
    },

};
