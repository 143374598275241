import { GlobalAction } from ".";
import { PromoServices } from "../services";

export const PROMOS_REQUEST = 'PROMOS_REQUEST';
export const PROMOS_SUCCESS = 'PROMOS_SUCCESS';
export const PROMOS_FAILURE = 'PROMOS_FAILURE';

export const CHANGE_PROMO_STATUS_REQUEST = 'CHANGE_PROMO_STATUS_REQUEST';
export const CHANGE_PROMO_STATUS_SUCCESS = 'CHANGE_PROMO_STATUS_SUCCESS';
export const CHANGE_PROMO_STATUS_FAILURE = 'CHANGE_PROMO_STATUS_FAILURE';

export const CREATE_PROMO_REQUEST = 'CREATE_PROMO_REQUEST';
export const CREATE_PROMO_SUCCESS = 'CREATE_PROMO_SUCCESS';
export const CREATE_PROMO_FAILURE = 'CREATE_PROMO_FAILURE';

export const UPDATE_PROMO_REQUEST = 'UPDATE_PROMO_REQUEST';
export const UPDATE_PROMO_SUCCESS = 'UPDATE_PROMO_SUCCESS';
export const UPDATE_PROMO_FAILURE = 'UPDATE_PROMO_FAILURE';

export const PROMO_DETAILS_REQUEST = 'PROMO_DETAILS_REQUEST';
export const PROMO_DETAILS_SUCCESS = 'PROMO_DETAILS_SUCCESS';
export const PROMO_DETAILS_FAILURE = 'PROMO_DETAILS_FAILURE';

export const PROMO_COUPON_CODES_REQUEST = 'PROMO_COUPON_CODES_REQUEST';
export const PROMO_COUPON_CODES_SUCCESS = 'PROMO_COUPON_CODES_SUCCESS';
export const PROMO_COUPON_CODES_FAILURE = 'PROMO_COUPON_CODES_FAILURE';

export const PROMOS_LIST_REQUEST = 'PROMOS_LIST_REQUEST';
export const PROMOS_LIST_SUCCESS = 'PROMOS_LIST_SUCCESS';
export const PROMOS_LIST_FAILURE = 'PROMOS_LIST_FAILURE';

export const PROMOS_STORY_REQUEST = 'PROMOS_STORY_REQUEST';
export const PROMOS_STORY_SUCCESS = 'PROMOS_STORY_SUCCESS';
export const PROMOS_STORY_FAILURE = 'PROMOS_STORY_FAILURE';

export const EXPORT_PROMO_REQUEST = 'EXPORT_PROMO_REQUEST';
export const EXPORT_PROMO_SUCCESS = 'EXPORT_PROMO_SUCCESS';
export const EXPORT_PROMO_FAILURE = 'EXPORT_PROMO_FAILURE';

export const CHANGE_PROMO_FEATURE_REQUEST = 'CHANGE_PROMO_FEATURE_REQUEST';
export const CHANGE_PROMO_FEATURE_SUCCESS = 'CHANGE_PROMO_FEATURE_SUCCESS';
export const CHANGE_PROMO_FEATURE_FAILURE = 'CHANGE_PROMO_FEATURE_FAILURE';

export const CHANGE_PROMOS_STATUS_REQUEST = 'CHANGE_PROMOS_STATUS_REQUEST';
export const CHANGE_PROMOS_STATUS_SUCCESS = 'CHANGE_PROMOS_STATUS_SUCCESS';
export const CHANGE_PROMOS_STATUS_FAILURE = 'CHANGE_PROMOS_STATUS_FAILURE';

export const CHANGE_BRAND_PROMO_FEATURE_REQUEST = 'CHANGE_BRAND_PROMO_FEATURE_REQUEST';
export const CHANGE_BRAND_PROMO_FEATURE_SUCCESS = 'CHANGE_BRAND_PROMO_FEATURE_SUCCESS';
export const CHANGE_BRAND_PROMO_FEATURE_FAILURE = 'CHANGE_BRAND_PROMO_FEATURE_FAILURE';


export const REDEEM_COUPON_REQUEST = 'REDEEM_COUPON_REQUEST';
export const REDEEM_COUPON_SUCCESS = 'REDEEM_COUPON_SUCCESS';
export const REDEEM_COUPON_FAILURE = 'REDEEM_COUPON_FAILURE';

export const GET_RUNNING_PROMOS_REQUEST = 'GET_RUNNING_PROMOS_REQUEST';
export const GET_RUNNING_PROMOS_SUCCESS = 'GET_RUNNING_PROMOS_SUCCESS';
export const GET_RUNNING_PROMOS_FAILURE = 'GET_RUNNING_PROMOS_FAILURE';

export const PromoAction = {

    /**
     * Action for list brand promos
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     * @created_at 15 June 2022
     */
    listBrandPromos: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.listBrandPromos(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PROMOS_REQUEST, request: request } }
        function success(request, response) { return { type: PROMOS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMOS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change promo status
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @author Jasin 
     * @created_at 15 June 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_PROMO_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_PROMO_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_PROMO_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
 * Action for update promo
 * @param {Object} params - The params which are used for the api
 * @param {String} params.promo_id
 * @param {String} params.name
 * @param {String} params.insta_handle
 * @param {String} params.description
 * @param {String} params.promo_type
 * @param {String} params.promo_start_date
 * @param {String} params.promo_end_date
 * @param {String} params.number_of_promos
 * @param {String} params.promo_cost_per_influencer
 * @param {Object} params.file
 * @author Jasin 
 * @created_at 15 June 2022
*/
    updatePromo: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.updatePromo(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_PROMO_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_PROMO_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_PROMO_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get promo details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.influencer_id
     * @author Jasin 
     * @created_at 15 June 2022
    */
    promoDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.promoDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: PROMO_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: PROMO_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMO_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list promo coupon codes
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     * @created_at 15 June 2022
     */
    listPromoCouponCodes: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.listPromoCouponCodes(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PROMO_COUPON_CODES_REQUEST, request: request } }
        function success(request, response) { return { type: PROMO_COUPON_CODES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMO_COUPON_CODES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list promos
     * @param {Object} params - The params which are used for the api
     * @author Naveen 
     * @created_at 21 April 2022
     */
    listPromos: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.listPromos(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PROMOS_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: PROMOS_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMOS_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list promo stories
     * @param {Object} params - The params which are used for the api
     * @author  
     * @created_at 15 June 2022
     */
    promoStoryDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.promoStoryDetails(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PROMOS_STORY_REQUEST, request: request } }
        function success(request, response) { return { type: PROMOS_STORY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMOS_STORY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action to export promo csv
    * @param {Object} params - The params which are used for the api
    * @author  
    * @created_at 15 June 2022
    */
    exportPromoCsv: (params) => {

        return dispatch => {
            dispatch(request(params));
            return PromoServices.exportPromoCsv(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: EXPORT_PROMO_REQUEST } }
        function success(response) { return { type: EXPORT_PROMO_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: EXPORT_PROMO_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change Promo Feature
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 27 June 2022
     */
    changeFeature: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.changeFeature(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_PROMO_FEATURE_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_PROMO_FEATURE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_PROMO_FEATURE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change admins promo status
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 18 July 2022
     */
    changePromoStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.changePromoStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_PROMOS_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_PROMOS_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_PROMOS_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change Promo Feature
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 27 June 2022
     */
    changePromoFeature: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.changePromoFeature(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_BRAND_PROMO_FEATURE_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_BRAND_PROMO_FEATURE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_BRAND_PROMO_FEATURE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action for redeem coupon
    * @param {Object} params - The params which are used for registration api.
    * @author  
    * @created_at 30 May 2022
    */
    redeemCoupon: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.redeemCoupon(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: REDEEM_COUPON_REQUEST, request: request } }
        function success(request, response) { return { type: REDEEM_COUPON_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: REDEEM_COUPON_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    createPromo: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.createPromo(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CREATE_PROMO_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_PROMO_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_PROMO_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action to get running promos
    * @param {Object} params - The params which are used for the api
    * @author Jasin
    * @created_at 30 May 2022
    */
    getRunningPromos: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.getRunningPromos(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: GET_RUNNING_PROMOS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_RUNNING_PROMOS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_RUNNING_PROMOS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
};
