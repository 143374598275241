import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SupportCenterAction } from '../../redux/actions';
import { PostAuthRoutes } from "../../routes";
import queryString from 'query-string';
import { Button, ButtonGroup, Chip } from '@mui/material';
import { DataTable } from '../../_components/data-table/DataTable';
import { useHistory } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import { AbilityCan } from '../../_helpers/permission/AbilityCan'

function SupportCenterDataTable({ statusUpdateModal, filterAction, filter, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { per_page, total, support_center_loading: isLoading, support_center } = useSelector((state) => state.SupportCenterReducer);
    const listSupportCenter = (params) => dispatch(SupportCenterAction.listSupportCenter(params));

    /** Initialize and declare state */
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [search] = useState('');
    const [limit] = useState(100);

    const columns = [
        { name: "No", center: true, width: '5%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: "Brand", selector: (row, i) => (row.brand_details.name), sortField: 'brand_details.name', sortable: true, center: true, width: '13%' },
        { name: 'Subject', selector: (row, i) => (row.subject), sortField: 'subject', sortable: true, width: '25%' },
        { name: 'Description', selector: (row, i) => (row.description), sortField: 'description', center: false, sortable: true, width: '37%', wrap: true, cell: row => (<DescriptionRow row={row} />) },
        { name: 'Created Date', selector: (row, i) => (row.created_at), sortField: 'created_at', center: true, sortable: true, width: '12%' },
        { name: 'Status', selector: (row, i) => (row.status), sortField: 'status', center: true, sortable: true, width: '8%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display ticket description on rows
     * @param {Object} row
     * @param {string} row.description - description on the row
     * @return json
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const DescriptionRow = ({ row }) => {
        return <p>{row.description.length > 150 ? row.description.substring(0, 150) + "..." : row.description}</p>;
    }

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const StatusRow = ({ row }) => {

        function handleDetailsClick(row) {
            let redirectPath = `${PostAuthRoutes('support_center_details').path}?tId=${row._id}`;
            history.push(redirectPath);
        }

        /**
         * function to change Support center status
         * @return view
         * @author
         * @created_at 21 June 2022
         */
        function handleStatusChange(row) {
            statusUpdateModal({ id: row._id, status: row.status, remark: '' });
        }

        let status = <Chip label={row.status} className="badge-status capitalize" />
        if (row.status === 'open')
            status = <Chip label={row.status} color="success" className="badge-status capitalize" />
        else if (row.status === 'closed')
            status = <Chip label={row.status} color="error" className="badge-status capitalize" />
        else if (row.status === 'hold')
            status = <Chip label={row.status} color="primary" className="badge-status capitalize" />
        else if (row.status === 're-open')
            status = <Chip label={row.status} color="secondary" className="badge-status capitalize" />
        else if (row.status === 'resolved')
            status = <Chip label={row.status} className="badge-status capitalize" />


        let statusHover = (
            <ButtonGroup variant="text" size="small" className='row-action'>
                <AbilityCan I='support_center_details' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Button onClick={() => handleDetailsClick(row)}> <Visibility /></Button>
                </AbilityCan>
                <AbilityCan I='support_center_change_status' passThrough={(user.role_slug === 'developer') ? true : false}>
                    {row.status === 'open' ? <Button onClick={() => handleStatusChange(row)}> <ChangeCircleIcon /></Button> : ''}
                </AbilityCan>
            </ButtonGroup>
        )

        return <React.Fragment>{status}{statusHover}</React.Fragment>;
    }


    useEffect(() => {

        var queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q
        let pageUrl = `${PostAuthRoutes('support_center').path}?${queryString.stringify(queryParam)}`;

        window.history.pushState({}, '', pageUrl);
        listSupportCenter({ page, search, sort, filter, limit });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, sort, filter])

    /**
     * function to sort columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={support_center}
            total={total}
            per_page={per_page}
            setPage={page => setPage(page)}
            handleSelect={handleSelect}
            handleSort={handleSort}
        />

    )
}
export { SupportCenterDataTable }