import { apiServices } from '../../_helpers';


export const GiveawayServices = {

    /**
     * Service for list brand giveaways
     * @param {Object} params - The params which are used for change password api
     * @author Jasin
     * @created_at 13 Jan 2023
     */
    listBrandGiveaways: (params) => {
        return apiServices.post('/admin/giveaway/list', params)
            .then((response) => { return response; })

    },

    /**
     * Service for change giveaway status
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 13 Jan 2023
     */
    changeStatus: (params) => {
        return apiServices.put('/admin/giveaway/change-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service for giveaway details
     * @author Jasin 
     */
    giveawayDetails: (params) => {
        return apiServices.post('/admin/giveaway/details', params)
            .then((response) => { return response; })

    },

    /**
     * Service for list promo coupon codes
     * @author Akshay N 
     */
    listBrandGiveawayCouponCodes: (params) => {
        return apiServices.post('/admin/giveaway/coupon-codes', params)
            .then((response) => { return response; })

    },

    /**
     * Service for list promo coupon codes
     * @author Akshay N 
     */
    getGiveawayWinner: (params) => {
        return apiServices.post('/admin/giveaway/winner/details', params)
            .then((response) => { return response; })

    },

    /**
     * Service for get giveaway entries
     * @author Akshay N 
     */
    listGiveawayEntries: (params) => {
        return apiServices.post('/admin/giveaway/participants/list', params)
            .then((response) => { return response; })

    },

    
    /**
     * Service to get login activity list
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    giveawayRedeemCoupon: (params) => {
        return apiServices.post('/admin/giveaway/coupon-codes/redeem', params)
            .then((response) => { return response; })
    },

    /**
     * Service to create giveaway 
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.name
     * @param {String} req.body.description
     * @param {String} req.body.start_date
     * @param {String} req.body.end_date
     * @param {String} req.body.thank_you_message
     * @param {Object} req.files.sample_story
     * @author  Jasin
     * @created_at 13 Jan 2023
     */
    createGiveaway: (params) => {
        return apiServices.post('/admin/giveaway/create', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },
    /**
     * Service for update giveaway
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @param {String} req.body.name
     * @param {String} req.body.description
     * @param {String} req.body.start_date
     * @param {String} req.body.end_date
     * @param {String} req.body.thank_you_message
     * @param {Object} req.files.sample_story
     * @author  Jasin
     * @created_at 13 Jan 2023
    */
    updateGiveaway: (params) => {
        return apiServices.put('/admin/giveaway/update', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },

    /**
     * Service for get giveaway entries
     * @author Akshay N 
     */
    getGiveawayDates: (params) => {
        return apiServices.post('/admin/giveaway/dates', params)
            .then((response) => { return response; })

    },

    /**
     * Service for list promo coupon codes
     * @author Akshay N 
     */
    getRunningGiveaways: (params) => {
        return apiServices.post('/admin/promo/running', params)
            .then((response) => { return response; })

    },
};
