import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Stack } from '@mui/material';
import { UpdateStatusModal } from '../../_components/modal/modals';
import { Add, FilterAlt } from '@mui/icons-material'
import { CardHeader } from "../../_components/card";
import { GeneralButton, FilterMenu, RouteLink } from "../../_components/controls";
import { PostAuthRoutes } from "../../routes";
import { PromoAction } from '../../redux/actions/PromoAction';
import { PromoCardLoader, PromoCard, PromoFilter, PromoExport } from ".";
import { useLocation } from "react-router-dom";
import { FormSearchInput } from '../../_components/form';
import { UpdateFeatureModal } from '../../_components/modal/modals'
import { NoData } from "../../_components/layout/no-data";
import { AbilityCan } from "../../_helpers/permission/AbilityCan";
import { GiveawayAction } from "../../redux/actions";


function Promos(params) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { promo_list_loading: isLoading, promo_list, per_page, page } = useSelector((state) => state.PromoReducer);
    const listPromos = (params) => dispatch(PromoAction.listPromos(params));
    const changeFeature = (params) => dispatch(PromoAction.changeFeature(params));
    const changePromoStatus = (params) => dispatch(PromoAction.changePromoStatus(params));
    const getRunningPromos = (params) => dispatch(PromoAction.getRunningPromos(params));
    const getRunningGiveaways = (params) => dispatch(GiveawayAction.getRunningGiveaways(params));

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [payload, setPayload] = useState({
        limit: per_page, page: page,
        filter: {
            status: searchParams.get('status') ?? '',
            date: searchParams.get('date') ?? '',
            feature: searchParams.get('feature') ?? ''
        },
        search: searchParams.get('q') ?? ''
    });
    const [showPromoFilterModal, setShowPromoFilterModal] = useState(false);
    const [showFeatureUpdateModal, setShowFeatureUpdateModal] = useState(false);
    const [featureData, setFeatureData] = useState({ id: '', feature: '' });

    useEffect(() => {

        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
        if (payload.filter.feature) queryParam.feature = payload.filter.feature; else delete queryParam.feature;
        let pageUrl = `${PostAuthRoutes('promo').path}?${queryString.stringify(queryParam)}`;

        window.history.pushState({}, '', pageUrl);
        getNext();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    
    useEffect(() => {

        getRunningPromos();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to list Promos
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNext = async () => {
        const result = await listPromos(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    /**
     * function to open/close status Update modal 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(true);
    }

    /**
     * function to update Promos
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!setShowStatusUpdateModal);
        changePromoStatus({ 'promo_id': id }).then((result) => {
            getRunningGiveaways();
            getRunningPromos();
        });
    }

    /**
     * function to open/close feature Update modal 
     * @return view
     * @author Naveen
     * @created_at 27 June 2022
     */
    const featureUpdateModal = ({ id, feature }) => {
        setFeatureData({ id: id, feature: feature });
        setShowFeatureUpdateModal(!showFeatureUpdateModal);
    }

    /**
     * function to update Promo feature
     * @return view
     * @author Naveen
     * @created_at 27 June 2022
     */
    const updateFeatureAction = async (id) => {
        setShowFeatureUpdateModal(!showFeatureUpdateModal);
        await changeFeature({ 'promo_id': id });
    }

    /**
     * function to apply the selected filter options on the Promos list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the Promos filter modal
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });

    }

    /**
     * function to apply the search key words on the Promos list
     * @param {String} text Search keyword used
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    /**
     * function to open/close Promo filter modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const promoFilterModal = () => {
        setShowPromoFilterModal(!showPromoFilterModal);
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('promo').name}
                    action={
                        <Stack spacing={1} direction='row' >
                            <AbilityCan I='create_promo' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <RouteLink to={PostAuthRoutes('create_promos').path} label={<GeneralButton startIcon={<Add />} label={PostAuthRoutes('create_promos').name} />} />
                            </AbilityCan>
                            <AbilityCan I='promo_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            </AbilityCan>
                            <AbilityCan I='promo_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <GeneralButton label={<FilterAlt />} onClick={() => promoFilterModal()} />
                            </AbilityCan>
                            <AbilityCan I='promo_export_csv' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <FilterMenu>
                                    <PromoExport payload={payload} />
                                </FilterMenu>
                            </AbilityCan>
                        </Stack>
                    }
                />
                <PromoFilter show={showPromoFilterModal} applyFilter={applyFilter} closeModal={promoFilterModal} promoFilter={payload.filter} />
                <InfiniteScroll dataLength={promo_list.length} next={getNext} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="stretch" >
                        {
                            promo_list.length > 0 && promo_list.map((promo, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={i}>
                                        <PromoCard promo={promo} statusUpdateModal={statusUpdateModal} featureUpdateModal={featureUpdateModal} />
                                    </Grid>
                                );
                            })
                        }
                        {isLoading && <PromoCardLoader />}
                        {!isLoading && promo_list.length === 0 && <NoData content1={`No`} content2={`Promo available`} minHeight={`55vh`} ></NoData>}
                    </Grid>
                </InfiniteScroll>
            </Box>
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
            <UpdateFeatureModal show={showFeatureUpdateModal} closeModal={featureUpdateModal} data={featureData} updateFeature={updateFeatureAction} />
        </React.Fragment>
    )

}

export { Promos }