import { Chip, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PermissionAction } from "../../../redux/actions";
import { FormSelect } from "../../../_components/form";
import { ConfzModal, ConfzModalBody, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from "../../../_components/modal";
import { handleInputChange } from "../../../_helpers";

// Initialize form input values to null
const inputs = { filter_status: '', filter_parent: '' };

// Declare filter status select options
const filterStatus = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
]

function PermissionFilter({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { permissions_select_list } = useSelector((state) => state.PermissionReducer);
    const permissionSelectList = (params) => dispatch(PermissionAction.permissionSelectList(params));

    /** Initialize and declare state */
    const { show, closeModal, applyFilter, permissionFilter } = props;
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    /**
     * function to close permission filter modal
     * @param {string} status Entered status on permission filter modal
     * @param {string} month Entered month on permission filter modal 
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const onClose = () => {
        setData({ filter_status: '', filter_parent: '' });
        closeModal(false);
    }

    /**
     * function to clear permission filter modal
     * @param {string} status Entered status on permission filter modal
     * @param {string} month Entered month on permission filter modal 
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const onClear = () => {
        setData({ filter_status: '', filter_parent: '' });
        applyFilter({ filter_status: '', filter_parent: '' });
        closeModal(false);
    }

    useEffect(() => {
        permissionSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setData({ filter_status: permissionFilter.filter_status, filter_parent: permissionFilter.filter_parent })

        let filtered = {};
        Object.keys(permissionFilter).forEach(function (item, i) {
            if (permissionFilter[item]) {
                filtered[item] = permissionFilter[item];
                if (item === 'filter_parent') {
                    let status = permissions_select_list.filter(function (obj) { return obj.value === permissionFilter[item] })
                    filtered[item] = status[0].label
                }
                return filtered[item];
            }
        });
        setFilteredData({ ...filtered });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionFilter])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Jasin
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author  
     * @created_at 14 June 2022 
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyFilter(data);
        closeModal(false);
    }

    /**
     * function to clear all data entered on permission filter modal
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem });
    }

    return (
        <React.Fragment>
            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={1}>
                                {
                                    Object.keys(filteredData).map((item, i) => {
                                        if (item === 'status') {
                                            const status = filterStatus.find(function (status) { return status.value === filteredData[item]; });
                                            return <Chip key={i} label={status.label} onDelete={() => clearFilterItem(item)} />
                                        } else {
                                            return <Chip key={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />
                                        }
                                    })
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                )
            }
            <ConfzModal isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="userFilter" onClose={onClose} title='Filter' />
                    <ConfzModalBody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSelect
                                    tabIndex={1}
                                    label={`Status`}
                                    name={`filter_status`}
                                    placeholder={`Select status`}
                                    onChange={handleChange}
                                    data={filterStatus}
                                    className="col-md-6"
                                    value={data.filter_status}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSelect
                                    tabIndex={2}
                                    label={`Parent Permission`}
                                    name={`filter_parent`}
                                    placeholder={`Select role`}
                                    onChange={handleChange}
                                    data={permissions_select_list}
                                    className="col-md-6"
                                    value={data.filter_parent}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                                    <ConfzModalFooterSaveBtn tabIndex={4} text='Filter' />
                                    <ConfzModalFooterCloseBtn tabIndex={3} onClose={onClear} text='Clear' />
                                </Stack>
                            </Grid>
                        </Grid>
                    </ConfzModalBody>
                </form>
            </ConfzModal>
        </React.Fragment>
    );
}

export { PermissionFilter }